.flujoHistorialContainer {
  width: 100%;
  height: 250px;
  background-color: rgba(102, 113, 128, 0.06);
}
.rowFlujoHistorial {
  height: 250px;
}
.colFlujoHistorialTitle__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 26px;
  color: #00606e;
  text-align: center;
  margin: 0;
  padding-top: 78px;
  padding-bottom: 22px;
}
.colFlujoDePaciente {
  height: 50%;
  padding: 0;
}
.rowIconosDelFlujo {
  width: 50%;
  height: 100%;
}
.colIcon {
  height: 100%;
}
.rowIcon {
  height: 100%;
}
.colContentImg {
  height: 50%;
}
.colContentImg__img {
  width: 50px;
  height: 50px;
}
.colContentP {
  height: 50%;
}
.colHorizontalLineContainer {
  padding: 0;
  padding-top: 10px;
  height: 100%;
}
.colHorizontalLineContainer__hr {
  border: 1px solid;
}
.containerHeaderTipoDeCita {
  width: 100%;
  height: 130px;
  padding: 0;
  margin: 0;
  background-color: rgba(102, 113, 128, 0.06);
}
.rowContainerHeaderTipoDeCita {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.colContainerButtonHeaderTipoDeCita {
  padding-right: 150px;
}
.colContainerButtonHeaderTipoDeCita__button {
  width: 15%;
  height: 50px;
  background-color: #fdcd2f;
  color: #00748c;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  border: none;
  border-radius: 10px;
  outline: none;
}
.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
}
.colContainerSpanBoton {
  padding: 0;
}

.colContainerButtonHeaderTipoDeCita__button:hover {
  background-color: #00748c;
  color: #fdcd2f;
}
.colContainerButtonHeaderTipoDeCita__span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fac61b;
}
.colContainerButtonHeaderTipoDeCita__svg {
  width: 15px;
  height: 15px;
  fill: #fff;
}
.colContainerButtonHeaderTipoDeCita__button:hover
  .colContainerButtonHeaderTipoDeCita__svg {
  fill: #00748c;
}
.stepperIconActive {
  width: 30px;
  height: 30px;
}
.stepperLabel {
  font-family: DINNextRoundedLTPro, sans-serif;
  color: #00606e;
  max-width: 123px;
  margin: auto;
}
.stepperLabelOMO {
  font-family: DINNextRoundedLTPro, sans-serif;
  color: #00606e;
  max-width: 65px;
  margin: auto;
}
@media (min-width: 1500px) {
  .rowFlujoHistorial {
    height: 350px;
  }
}

@media (max-width: 1500px) {
  .colContainerButtonHeaderTipoDeCita__button {
    width: 20%;
  }
  .colContainerButtonHeaderTipoDeCita {
    padding-right: 100px;
  }
}
@media (min-height: 1200px) {
  .rowFlujoHistorial {
    height: 350px;
  }
}
@media (max-width: 1200px) {
  .colContainerButtonHeaderTipoDeCita__button {
    width: 20%;
  }
  .colContainerButtonHeaderTipoDeCita {
    padding-right: 50px;
  }
}
@media (max-width: 992px) {
  .rowFlujoHistorial {
    height: 350px;
  }
}
@media (max-width: 768px) {
  .colContainerButtonHeaderTipoDeCita {
    align-items: center !important;
  }
}
@media (max-width: 661px) {
  .colContainerButtonHeaderTipoDeCita__button {
    width: 30%;
  }
}

@media (max-width: 400px) {
  .colContainerButtonHeaderTipoDeCita__span {
    margin-left: 0;
  }
  .colContainerButtonHeaderTipoDeCita__button {
    padding-left: 0;
  }
  .colFlujoHistorialTitle__title {
    font-size: 20px;
    text-align: center;
    padding-bottom: 10px;
  }
}
@media (max-width: 474px) {
  .colContainerButtonHeaderTipoDeCita__button {
    width: 40%;
    padding: 0;
  }
  .stepperLabel {
    font-size: 10px;
  }
  .stepperLabelOMO {
    font-size: 10px;
  }
  .rowFlujoHistorial {
    height: 300px;
  }
}

@media (max-width: 400px) {
  .colFlujoHistorialTitle {
    align-items: center !important;
    height: 50%;
  }
  .rowFlujoHistorial {
    height: 250px;
  }
  .colContainerButtonHeaderTipoDeCita__button {
    width: 50%;
  }
}
