/* //!Comienzan estilos para ComoLlego */
.comoLlegoContainer {
}
.rowContainerNombreClinica {
  width: 100%;
}
.colContainerNombreClinica {
}
.colContainerNombreClinica__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 26px;
  color: #00606e;
  margin: 0;
}
.colContainerNombreClinica__border {
  border: 1px solid #ffd83d;
  width: 67px;
  margin-bottom: 40px;
}
.rowContainerComoLlegar {
  width: 100%;
}
.colContainerComoLlegar {
}
.colContainerComoLlegar__p {
  margin: 0;
  padding: 0;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
  color: #00606e;
}
.rowContainerMedioDeLlegada {
  width: 100%;
}
.colContainerMedioDeLlegada {
}
.colContainerMedioDeLlegadaTransporte {
}
.colContainerMedioDeLlegadaTransporteTitle {
}
.colContainerMedioDeLlegadaTransporteTitle__title {
  margin: 0;
  padding-left: 10px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 15px;
  color: #00606e;
}
.colContainerMedioDeLlegadaTransporte__p {
  margin: 0;
  margin-left: 35px;
  margin-bottom: 15px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 15px;
  color: #00606e;
}
@media (max-width: 443px) {
  .colContainerNombreClinica__title {
    font-size: 18px;
  }
  .colContainerComoLlegar__p {
    font-size: 15px;
  }
  .colContainerMedioDeLlegadaTransporteTitle__title {
    font-size: 15px;
  }
  .colContainerMedioDeLlegadaTransporte__p {
    font-size: 15px;
  }
}
@media (max-width: 661px) {
  .colContainerNombreClinica__title {
    display: none;
  }
  .colContainerNombreClinica__border {
    display: none;
  }
}
/* //!Terminan estilos para ComoLlego */
