/* Extra- Small devices verticales (landscape phones, 576px and up) */

@media (min-width: 300px) and (max-width: 400px) {
  .col_preguntaRespuesta {
    padding-top: 2%;
    padding-left: 8%;
    padding-right: 8%;
    color: #00788b;
  }

  legend {
    font-size: 22px;
    font-family: ChivoRegular, sans-serif;
    font-weight: bold;
    text-align: initial;
  }

  .respuestasCuestionario {
    font-family: ChivoRegular, sans-serif;
    font-weight: normal;
    font-size: 20px;
    text-align: initial;
  }
}

@media (min-width: 401px) and (max-width: 575px) {
  .col_preguntaRespuesta {
    padding-top: 2%;
    padding-left: 8%;
    padding-right: 8%;
    color: #00788b;
  }

  legend {
    font-size: 20px;
    font-family: ChivoRegular, sans-serif;
    font-weight: bold;
    text-align: initial;
  }

  .respuestasCuestionario {
    font-family: ChivoRegular, sans-serif;
    font-weight: normal;
    font-size: 16px;
    text-align: initial;
  }
}

/* Small devices  horizontales (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 680px) {
  .col_preguntaRespuesta {
    padding-top: 2%;
    padding-left: 8%;
    padding-right: 8%;
    color: #00788b;
  }

  legend {
    font-size: 20px;
    font-family: ChivoRegular, sans-serif;
    font-weight: bold;
    text-align: initial;
  }

  .respuestasCuestionario {
    font-family: ChivoRegular, sans-serif;
    font-weight: normal;
    font-size: 16px;
    text-align: initial;
  }
}

@media (min-width: 681px) and (max-width: 767px) {
  .col_preguntaRespuesta {
    padding-top: 2%;
    padding-left: 8%;
    padding-right: 8%;
    color: #00788b;
  }

  legend {
    font-size: 20px;
    font-family: ChivoRegular, sans-serif;
    font-weight: bold;
    text-align: initial;
  }

  .respuestasCuestionario {
    font-family: ChivoRegular, sans-serif;
    font-weight: normal;
    font-size: 16px;
    text-align: initial;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .col_preguntaRespuesta {
    padding-top: 2%;
    padding-left: 8%;
    padding-right: 8%;
    color: #00788b;
  }

  legend {
    font-size: 22px;
    font-family: ChivoRegular, sans-serif;
    font-weight: bold;
    text-align: initial;
  }

  .respuestasCuestionario {
    font-family: ChivoRegular, sans-serif;
    font-weight: normal;
    font-size: 20px;
    text-align: initial;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .col_preguntaRespuesta {
    padding-top: 2%;
    padding-left: 8%;
    padding-right: 8%;
    color: #00788b;
  }

  legend {
    font-size: 22px;
    font-family: ChivoRegular, sans-serif;
    font-weight: bold;
    text-align: initial;
  }

  .respuestasCuestionario {
    font-family: ChivoRegular, sans-serif;
    font-weight: normal;
    font-size: 20px;
    text-align: initial;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .col_preguntaRespuesta {
    padding-top: 2%;
    padding-left: 7%;
    color: #00788b;
  }

  legend {
    font-size: 22px;
    font-family: ChivoRegular, sans-serif;
    font-weight: bold;
    text-align: initial;
  }

  .respuestasCuestionario {
    font-family: ChivoRegular, sans-serif;
    font-weight: normal;
    font-size: 20px;
    text-align: initial;
  }
}

/* Extra large super devices (large desktops, 1500px and up) */
@media (min-width: 1500px) {
  .col_preguntaRespuesta {
    padding-top: 2%;
    padding-left: 7%;
    color: #00788b;
  }

  legend {
    font-size: 22px;
    font-family: ChivoRegular, sans-serif;
    font-weight: bold;
    text-align: initial;
  }

  .respuestasCuestionario {
    font-family: ChivoRegular, sans-serif;
    font-weight: normal;
    font-size: 20px;
    text-align: initial;
  }
}
