input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  display: block;
  width: 19px;
  height: 19px;
  background-clip: content-box;
  border: 2px solid #ffcd00;
  background-color: white;
  border-radius: 50%;
  outline: none;
}
input[type="radio"]:checked {
  width: 19px;
  height: 19px;
}
input[type="radio"]:checked::after {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: -8px;
  left: 4px;
  position: relative;
  background-color: transparent;
  content: "";
  display: inline-block;
  visibility: visible;
  background-color: #ffcd00;
}
@media(max-width:767px){
  .rowContainerFormasDePagoSelector{
    padding-top: 50px;
  }
}

@media (max-device-width: 443px) {
  input[type="radio"]:checked::after {
    top: -7px;
    bottom: 7px;
  }
  .rowContainerFormasDePagoSelector{
    padding-top: 10px !important;
  }
}

.containerSelector {
  /* width: 100%; */
  height: 100%;
}
.rowContainerFormasDePagoSelector {
  align-items: center;
  padding-top: 10px;
}
.colMetodoDePago {
  /* margin-bottom: -50px; */
}
.colMetodoDePago__p {
  color: #10798b;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  text-decoration: underline;
  font-size: 22px;
}
.colSpei {
}
.colSpei__label {
  display: flex;
  align-items: center;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #10798b;
  font-size: 18px;
}
.colSpei__input {
  margin-right: 10px;
}
.colOxxo {
}
.colOxxo__label {
  display: flex;
  align-items: center;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #10798b;
  font-size: 18px;
}
.colOxxo__input {
  margin-right: 10px;
}
.colCard {
}
.colCard__label {
  display: flex;
  align-items: center;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #10798b;
  font-size: 18px;
}
.colCard__input {
  margin-right: 10px;
}
