.GeneralHeaderContainer {
  width: 100%;
  height: 103px;
  position: relative;
  background-color: #00778b;
  padding-top: 1%;
  padding-bottom: 1%;
  box-shadow: 0px 4px 5px 0px rgba(102, 113, 128, 0.5);
}
.backgroundCI {
  background-color: #47c3cc;
}
.shadow {
  box-shadow: 0px 4px 5px 0px rgba(0, 96, 110, 0.4);
  z-index: 100;
}
.rowGeneralHeader {
  /* background-color: red; */
  margin: 0 25px;
  padding: 10px;
  width: 100%;
}
.colContainerButton {
  justify-content: flex-end;
}
.colContainerButtonCC {
  justify-content: flex-end;
}
.colContainerButtons {
  display: flex;
  justify-content: flex-end;
}
.rowContainerButtons {
  width: 75%;
}
.colContainerButtonInicio {
  justify-content: flex-end;
}
.colContainerButtonInicio__button {
  width: 100%;
  height: 40px;
  max-width: 233px;
  background-color: #fff;
  font-family: DINNextRoundedLTPro, sans-serif;
  color: #00788a;
  border: none;
  border-radius: 20px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.colContainerButtonCC__button {
  width: 100%;
  height: 40px;
  max-width: 233px;
  background-color: #fff;
  font-family: DINNextRoundedLTPro, sans-serif;
  color: #00788a;
  border: none;
  border-radius: 20px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.colContainerButton__button {
  width: 100%;
  height: 40px;
  max-width: 233px;
  background-color: #fff;
  font-family: DINNextRoundedLTPro, sans-serif;
  color: #00788a;
  border: none;
  border-radius: 20px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.colContainerButtonCC__button:hover {
  color: #00788a;
  text-decoration: none;
}
.colContainerButton__button:hover {
  color: #00788a;
  text-decoration: none;
}

.colGeneralHeaderLogo__imgCI {
  width: 125px !important;
}
/* Extra- Small devices verticales (landscape phones, 576px and up) */
@media (min-width: 300px) and (max-width: 400px) {
  .colGeneralHeaderLogo {
    padding-left: 7.5%;
  }

  .colGeneralHeaderLogo__img {
    width: 110px;
  }

  .GeneralHeaderContainer {
    padding-left: 2.5%;
  }
}

@media (min-width: 401px) and (max-width: 575px) {
  .colGeneralHeaderLogo {
    padding-left: 7.5%;
  }

  .colGeneralHeaderLogo__img {
    width: 110px;
  }

  .GeneralHeaderContainer {
    padding-left: 2.5%;
  }
}
@media (max-width: 443px) {
  .GeneralHeaderContainer {
    height: 70px;
  }
}

/* Small devices  horizontales (landscape phones, 576px and up) */
@media (max-width: 575px) {
  .colContainerButton__button {
    max-width: 170px;
    font-size: 12px;
    /* height: 30px; */
  }
  .colContainerButtonCC__button {
    max-width: 170px;
    font-size: 12px;
    /* height: 30px; */
  }
}
@media (min-width: 576px) and (max-width: 680px) {
  .colGeneralHeaderLogo {
    padding-left: 6.5%;
  }

  .colGeneralHeaderLogo__img {
    width: 110px;
  }

  .GeneralHeaderContainer {
    padding-left: 2.5%;
  }
}

@media (min-width: 681px) and (max-width: 767px) {
  .colGeneralHeaderLogo {
    padding-left: 6.5%;
  }

  .colGeneralHeaderLogo__img {
    width: 110px;
  }

  .GeneralHeaderContainer {
    padding-left: 2.5%;
  }
}
@media (max-width: 767px) {
  .rowGeneralHeader {
    margin: 0;
  }
  .colContainerButtonCC__button {
    font-size: 12px;
    text-align: center;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .colGeneralHeaderLogo {
    padding-left: 7%;
  }

  .GeneralHeaderContainer {
    padding-top: 0.7%;
    padding-bottom: 0.7%;
    padding-left: 2%;
  }

  .colGeneralHeaderLogo__img {
    width: 130px;
  }
}
@media (max-width: 991px) {
  .rowContainerButtons {
    width: 100%;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .GeneralHeaderContainer {
    padding-top: 0.7%;
    padding-bottom: 0.7%;
    padding-left: 2%;
  }

  .colGeneralHeaderLogo__img {
    width: 130px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .colGeneralHeaderLogo {
    padding-left: 5.5%;
  }

  .GeneralHeaderContainer {
    padding-top: 0.7%;
    padding-bottom: 0.7%;
    padding-left: 2%;
  }

  .colGeneralHeaderLogo__img {
    width: 166px;
  }
}

/* Extra large super devices (large desktops, 1500px and up) */
@media (min-width: 1500px) {
  .colGeneralHeaderLogo {
    padding-left: 5.5%;
  }

  .GeneralHeaderContainer {
    padding-top: 0.7%;
    padding-bottom: 0.7%;
    padding-left: 2%;
  }

  .colGeneralHeaderLogo__img {
    width: 166px;
  }
}
