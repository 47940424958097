.containerNoDisponible {
  background-color: rgba(102, 113, 128, 0.06);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100% - 206px);
  padding: 50px 0;
}
.titulo {
  text-align: center;
  font-family: DINNextRoundedLTPro, sans-serif;
  font-size: 2rem;
  color: #00778b;
  font-weight: 500;
  margin-top: 5%;
}
.subtitulo {
  text-align: center;
  font-family: DINNextRoundedLTPro, sans-serif;
  font-size: 1.5rem;
  color: #1ecad3;
  font-weight: 500;
  margin-top: 5%;
}

.infoImagen {
  margin-top: 5%;
}
