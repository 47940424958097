.container {
  width: 100%;
  position: relative;
  border: 4px solid #fff;
  border-radius: 20px;
  padding: 20px 0;
}
.rowContainer {
  height: 100%;
  width: 100%;
}
.colContainerForm {
  height: 100%;
}
.form {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.formContainer {
  height: 100%;
}
.rowTitle {
  /* margin-bottom: -20px; */
}
.colTitle {
}
.colTitle__title {
  color: #10798b;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  text-decoration: underline;
  font-size: 22px;
}
.rowDataCard1 {
}
.colNumeroTarjetaContainer {
}
.rowNumeroTarjetaContainer {
}
.colTitleNumeroTarjeta {
}
.colTitleNumeroTarjeta__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #10798b;
  font-size: 16px;
}
.colNumeroTarjeta {
}
.colNumeroTarjetaInputContainer {
  padding-right: 0;
}
.colNumeroTarjeta__input,
.colNumeroTarjeta__input:focus {
  width: 100%;
  max-width: 300px;
  height: 50px;
  border: 2px solid #10798b;
  border-radius: 12.5px;
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  background-color: transparent;
  font-size: 18px;
  padding-left: 10px;
  outline: none;
}
.colNumeroTarjeta__input::-webkit-outer-spin-button,
.colNumeroTarjeta__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.colNumeroTarjeta__input::placeholder {
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
}
.colNumeroTarjetaSvgContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: red; */
  margin: 0;
  padding: 0;
  height: 20px;
}
.colNumeroTarjeta__span {
}
.colNumeroTarjeta__svg {
  width: 100px;
  height: 50px;
  margin-left: -5px;
  margin-bottom: -10px;
  fill: #10798b;
}
.colFechaVencimientoContainer {
  display: flex;
}
.rowFechaVencimientoContainer {
}
.colFechaVencimientoMes {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.rowMesAñoContainer {
}
.colTitleFechaVencimiento {
  /* position: absolute; */
  top: 2%;
  left: 0;
}
.colTitleFechaVencimiento__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #10798b;
  font-size: 16px;
  width: 200%;
}
.colFechaVencimientoMes__input,
.colFechaVencimientoMes__input:focus {
  width: 100%;
  max-width: 145px;
  height: 50px;
  border: 2px solid #10798b;
  border-radius: 12.5px;
  color: rgb(146, 141, 141);
  outline: none;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  background-color: transparent;
  font-size: 18px;
  padding-left: 10px;
}
.colFechaVencimientoMes__input::-ms-expand {
  color: red;
}
.colFechaVencimientoMes__input::placeholder {
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
}

.colFechaVencimientoAñoContainer {
}
.rowFechaVenicimientoAñoContainer {
}
.colTitleFechaVencimientoAño {
  margin-bottom: 9px;
}
.colTitleFechaVencimientoAño__title {
}
.colFechaVencimientoAño {
}
.colFechaVencimientoAño__input,
.colFechaVencimientoAño__input:focus {
  width: 100%;
  height: 50px;
  max-width: 135px;
  border: 2px solid #10798b;
  border-radius: 12.5px;
  color: rgb(146, 141, 141);
  outline: none;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
  padding-left: 10px;
  background-color: transparent;
}
.colFechaVencimientoAño__input::-webkit-outer-spin-button,
.colFechaVencimientoAño__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.colFechaVencimientoAño__input::placeholder {
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
}

.rowDataCard2 {
}
.colNombreTarjetaContainer {
  padding-right: 0;
}
.rowNombreTarjetaContainer {
}
.colTitleNombreTarjeta {
}
.colTitleNombreTarjeta__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #10798b;
  font-size: 16px;
}
.colNombreTarjeta {
}
.colNombreTarjeta__input,
.colNombreTarjeta__input:focus {
  width: 100%;
  max-width: 300px;
  height: 50px;
  border: 2px solid #10798b;
  border-radius: 12.5px;
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  background-color: transparent;
  font-size: 18px;
  padding-left: 10px;
  outline: none;
}
.colNombreTarjeta__input::placeholder {
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
}
.colCvvContainer {
}
.rowCvvContainer {
}
.colTitleCvv {
}
.colTitleCvv_title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #10798b;
  font-size: 16px;
}
.colCvv {
}
.colCvv__input,
.colCvv__input:focus {
  width: 100%;
  max-width: 135px;
  height: 50px;
  border: 2px solid #10798b;
  border-radius: 12.5px;
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  background-color: transparent;
  font-size: 18px;
  padding-left: 10px;
  outline: none;
  appearance: none;
}
.colCvv__input::-webkit-outer-spin-button,
.colCvv__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}
.colCvv__input::placeholder {
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
}

@media (max-width: 1199px) {
  .colTitleFechaVencimiento {
    margin-top: -20px;
  }
}

@media (max-width: 991px) {
  .colTitleFechaVencimiento {
    margin-top: 0px;
  }
  .container {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .colFechaVencimientoMes {
    justify-content: initial;
    padding-right: 5px;
  }
  .rowFechaVencimientoContainer {
    padding-top: 15px;
  }
  .colCvvContainer {
    padding-top: 25px;
  }
}
@media (max-width: 577px) {
  .colFechaVencimientoAño {
    margin-top: 23px;
    padding-right: 0;
  }
  .rowFechaVencimientoContainer {
    padding-top: 0 !important;
  }
}

@media (max-width: 400px) {
  .colFechaVencimientoAño {
    padding-right: 1px;
  }
  .colFechaVencimientoMes {
    padding-right: 5px;
  }
}
