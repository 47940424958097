.containerTipoConsultaPresencialOmo {
	display: flex;
	align-items: baseline;
	width: 100%;
	height: auto;
	min-height: calc(100% - 206px);
	margin: 0;
	padding-bottom: 20px;
	background-color: rgba(102, 113, 128, 0.06);
	padding: 0;
	padding-bottom: 30px;
}

.container {
	height: 70%;
}
.rowContainerTitles {
	margin-top: 40px;
}
.colTitle1 {
	padding-bottom: 5px;
}
.colTitle1__title {
	display: block;
	/* width: 60%; */
	color: #00606e;
	font-family: DINNextRoundedLTPro-Medium, sans-serif;
	font-size: 40px;
	text-align: center;
	/* max-width: 491px; */
	margin: 0;
}
.colTitle1__span {
	text-align: center;
	color: #00606e;
	font-family: DINNextRoundedLTPro, sans-serif;
}
.colTitle2__title {
	display: block;
	width: 100%;
	text-align: center;
	font-family: DINNextRoundedLTPro-Regular, sans-serif;
	color: #00606e;
	font-size: 30px;
	max-width: 799px;
}
.colTitle2__span {
	color: #00606e;
	font-family: DINNextRoundedLTPro-Medium, sans-serif;
}

.rowContainerTipoConsultaPresencialOmo {
	padding-top: 46px;
	height: 100%;
}
.modalContainer {
	width: auto;
	height: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.backdropModal {
	background-color: #00788a;
	opacity: 90% !important;
}
.modalContanet {
	border-radius: 20px;
}
.modalHeader {
	border: none;
}
.modalButtonClosed {
	color: #00788a;
}

.textTitle {
	color: #00788b;
	font-size: 40px;
	font-family: DINNextRoundedLTPro, sans-serif;
	font-weight: 500;
}

.colPresencialContainer {
	height: 100%;
}

.containerPresencial {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	flex-direction: column;
}

.containerPresencialReverso {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}

.rowPresencialContainer {
	position: relative;
	width: 75%;
	border-radius: 20px;
	margin-bottom: 15px;
	background-color: #fff;
	border: 3px solid #fff;
	max-width: 246px;
	max-height: 400px;
	padding: 45px 30px;
}

.colIconPresencial__img {
	width: 50px;
	height: 50px;
	margin-bottom: 10px;
}

.colInfoPresencial {
	height: 320px;
	padding: 0;
	margin: 0;
}

.colInfoTitle__title {
	color: #00788a;
	font-family: DINNextRoundedLTPro-Medium, sans-serif;
	font-size: 26px;
	margin: 0;
}

.colInfoTitle__span {
	font-family: DINNextRoundedLTPro, sans-serif;
}

.colInfoPrice__p {
	font-family: DINNextRoundedLTPro-Medium, sans-serif;
	font-size: 14px;
	color: #00788a;
	margin: 0;
	margin-bottom: 5px;
}

.colInfo__p {
	font-family: DINNextRoundedLTPro-Medium, sans-serif;
	font-size: 14px;
	color: #00788a;
	margin: 0;
}
.colInfo__a {
	text-decoration: underline;
	color: #00788a;
}
.colInfo__a:link {
	color: #00788a;
}

.rowPresencialContainerButton {
	width: 100%;
	height: 60px;
}
.colContainerRecomendada {
	position: absolute;
	top: -17px;
	left: 0;
}
.colContainerRecomendada__div {
	display: flex;
	background-color: #fac61b;
	align-items: flex-end;
	width: 65%;
	height: 40px;
	justify-content: center;
	border-radius: 20px;
	max-width: 136px;
	max-height: 31px;
}
.colIconOmo {
	padding: 0;
	margin: 0;
}
.colContainerRecomendada__img {
	margin: auto 0;
}
.colContainerRecomendada__p {
	margin: auto 0;
	padding-left: 5px;
	color: #00788a;
	font-family: DINNextRoundedLTPro-Medium, sans-serif;
	font-size: 14px;
}
.colPresencialContainerButton {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: flex-end;
	padding: 0;
}
.transparente {
	background-color: transparent !important;
	border: 1px solid #00788a !important;
}
.transparente:hover {
	background-color: #00748c !important;
	color: #fff !important;
}
.colPresencialContainerButton__button,
.colPresencialContainerButton__button:focus {
	width: 100%;
	height: 50px;
	padding: 0 20px;
	background-color: #ffd83d;
	color: #00748c;
	font-family: DINNextRoundedLTPro-Medium, sans-serif;
	font-size: 14px;
	border: none;
	border-radius: 10px;
	outline: none;
	max-width: 244px;
}

.colPresencialContainerButton__button:hover {
	background-color: #00748c;
	color: #fff;
}
.colPresencialContainerButton__button:hover span {
	background-color: transparent;
}

.colPresencialContainerButton__button:hover .colPresencialContainerButton__svg {
	fill: #fff;
}
.colContainerNombreBoton {
	padding: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.colContainerSpanBoton {
	padding: 0;
	padding-left: 45px;
}
.colContainerSpanBotonOMO {
	padding: 0;
}
.colPresencialContainer__span {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: #fac61b;
}

.colPresencialContainerButton__svg {
	width: 15px;
	height: 15px;
	fill: #fff;
}
.colContainerSeparador__separador {
	height: 250px;
	border: 1px solid #00748c;
}
.colOmoContainer {
	height: 100%;
}

.containerOmo {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}

.containerOmoReverso {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	flex-direction: column;
}

.rowOmoContainer {
	position: relative;
	width: 75%;
	padding: 15px 0;
	border-radius: 20px;
	margin-bottom: 15px;
	max-width: 246px;
	max-height: 400px;
	padding: 45px 30px;
}

.colIconOmo__img {
	width: 50px;
	height: 50px;
	margin-bottom: 10px;
}

.colInfoOmo {
	height: 320px;
	padding: 0;
	margin: 0;
}

.rowOmoContainerButton {
	width: 100%;
	height: 60px;
}

.colOmoContainerButton {
	width: 100%;
	height: 60px;
	padding: 0;
}
.colorAmarillo {
	background-color: #ffd83d !important;
	border: none !important;
}
.colorAmarillo:hover {
	background-color: #00748c !important;
}
.colorAmarillo:hover .colPresencialContainer__span {
	background-color: transparent !important;
}
.colOmoContainerButton__button,
.colOmoContainerButton__button:focus {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 50px;
	background-color: transparent;
	color: #00748c;
	font-family: DINNextRoundedLTPro-Medium, sans-serif;
	font-size: 14px;
	border: 1px solid #00788a;
	border-radius: 10px;
	outline: none;
	max-width: 244px;
}

.colOmoContainerButton__button:hover {
	background-color: #00748c;
	color: #fff;
}
.colOmoContainerButton__button:hover .colOmoContainer__span {
	background-color: transparent;
}

.colOmoContainerButton__button:hover .colOmoContainerButton__svg {
	fill: #fff;
}

.colOmoContainer__span {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: #00606e;
}

.colOmoContainerButton__svg {
	width: 15px;
	height: 15px;
	fill: #fff;
}

.mBorderColorActive {
	border: 3px solid #fac61b;
}
.rowReverso {
	position: relative !important;
	width: 75% !important;
	padding: 15px 0 !important;
	border-radius: 20px !important;
	margin-bottom: 15px !important;
	max-width: 246px !important;
	max-height: 400px !important;
	padding: 45px 30px !important;
}

.modalTipoConsultaPresencialOmo__header {
	background-color: #10798b;
	color: #fdcd2f;
}
.modalTipoConsultaPresencialOmo {
	padding-top: 20px;
}
.modalTipoConsultaPresencialOmo__p {
	color: #00748c;
	font-family: DINNextRoundedLTPro-Medium, sans-serif;
	text-align: center;
}

.modalTipoConsultaPresencialOmo__button,
.modalTipoConsultaPresencialOmo__button:active,
.modalTipoConsultaPresencialOmo__button:focus {
	background-color: transparent;
	border: 1px solid #10798b;
	color: #10798b;
	font-family: DINNextRoundedLTPro-Medium,sans-serif;
	width: 100%;
	border-radius: 12.5px;
	height: 45px;
	margin-top: 10px;
}
.modalTipoConsultaPresencialOmo__buttonEspecialista,
.modalTipoConsultaPresencialOmo__buttonEspecialista:active,
.modalTipoConsultaPresencialOmo__buttonEspecialista:focus {
	background-color: #fdcd2f;
	border: none;
	width: 100%;
	color: #10798b;
	font-family: DINNextRoundedLTPro-Medium,sans-serif;
	border-radius: 12.5px;
	height: 45px;
	outline: none;
}
.modalTipoConsultaPresencialOmo__buttonEspecialista:hover {
	background-color: #10798b;
}

.colModalTipoConsultaPresencialOmoButton {
	justify-content: flex-end;
}

.mPaddingRight {
	padding-right: 10px;
}
@media (min-height: 1080px) {
	.containerTipoConsultaPresencialOmo {
		height: 100%;
		/* align-items: center; */
	}
}
@media (max-width: 991px) {
	.container {
		height: 100%;
	}

	.containerPresencial {
		align-items: center;
	}

	.containerPresencialReverso {
		align-items: center;
	}

	.containerOmo {
		align-items: center;
	}

	.containerOmoReverso {
		align-items: center;
	}
	.colOmoContainer {
		margin-top: 15px;
	}
	.colContainerSeparador {
		display: none !important;
	}
	.colPresencialContainer {
		margin-top: 15px;
	}
	.colOmoContainerButton__button {
		width: 100%;
	}
	.colPresencialContainerButton {
		justify-content: center;
	}
	.colOmoContainerButton {
		display: flex;
		justify-content: center;
	}
}
@media (max-width: 768px) {
	.colTitle1__title {
		width: 100%;
		font-size: 35px;
	}
	.colTitle2__title {
		font-size: 25px;
	}
	.rowPresencialContainer {
		width: 50%;
	}
	.rowOmoContainer {
		width: 50%;
	}
	.rowReverso {
		width: 50% !important;
	}
	.rowPresencialContainerButton {
		width: 50%;
	}
	.rowOmoContainerButton {
		width: 50%;
	}
}
@media (max-width: 661px) {
	.colTitle2__title {
		font-size: 20px;
	}
	.rowPresencialContainer {
		width: 80%;
	}
	.rowOmoContainer {
		width: 80%;
	}
	.rowReverso {
		width: 80% !important;
	}
	.rowPresencialContainerButton {
		width: 80%;
	}
	.rowOmoContainerButton {
		width: 80%;
	}
}
@media (max-width: 474px) {
	.colInfoTitle__title {
		font-size: 22px;
	}

	.rowPresencialContainer {
		height: 350px;
	}

	.rowOmoContainer {
		height: 400px;
	}
	.rowReverso {
		height: 400px !important;
	}

	.colOmoContainerButton__button {
		padding: 0;
	}
	.colTitle1__title {
		font-size: 25px;
	}
	.colTitle2__title {
		font-size: 20px;
	}
}

@media (max-width: 400px) {
	.rowPresencialContainer {
		width: 100%;
	}

	.rowOmoContainer {
		width: 100%;
	}
	.rowReverso {
		width: 100% !important;
	}

	.rowPresencialContainerButton {
		width: 90%;
	}

	.colPresencialContainer__span {
		margin-left: 0;
	}

	.rowOmoContainerButton {
		width: 90%;
	}

	.colOmoContainerButton__button {
		padding-left: 0;
	}

	.colOmoContainer__span {
		margin-left: 5px;
	}
}

/* //! estilos para piloto de omo */

.displayAlingnCenterProvicional {
	align-items: center !important;
}
.displatJustifyCenterProvicional {
	justify-content: center !important;
}
