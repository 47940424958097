.container {
  padding: 0;
  margin: 0;
  padding-top: 46px;
  background-color: rgba(102, 113, 128, 0.06);
}
.containerFormasDePago {
  padding: 0 !important;
}
.rowContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.colFormaDePagoSelector {
  height: 330px;
  width: 93%;
}
.colFormaDePago {
}
.colFormaDePagTarjeta {
  width: 100%;
  padding: 0;
}
.rowContainerFormaDePago {
  width: 90%;
}
.colContainerFormaDePago {
  padding: 0;
}
.colContainerFormaDePagoTarjeta {
  padding: 0;
  width: 100%;
}
.rowContainerFormaDePagoTarjeta {
  width: 90%;
}
.colFormaDePagoTarjeta {
  padding: 0;
}
.rowContainerPriceButton {
  width: 100%;
}
.rowContainerButon {
  width: 67%;
}
.colContainerPagoCitaButton {
  display: flex;
  justify-content: center;
}
.colContainerPrecio {
  display: flex;
  margin: auto 0;
  justify-content: flex-end;
}
.colContainerPrecio__label {
  margin: 0;
  color: #10798b;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 40px;
}
.colContainerButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0;
}
.colContainerButton__button {
  width: 100%;
  max-width: 244px;
  height: 50px;
  padding: 10px 15px;
  background-color: #ffd83d;
  color: #10798b;
  font-family: DINNextRoundedLTPro, sans-serif;
  font-size: 14px;
  border-radius: 12.5px;
  border: none;
  outline: none;
}
.colContainerButton__button span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffcd00;
}
.colContainerButton__button span svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
.colContainerButton__button:hover {
  background-color: #00748c;
  color: #fff;
}

.colContainerButton__button:hover span {
  background-color: transparent;
}

.buttonContainerParams {
}
.rowContainerParams {
}
.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.colContainerSpanBoton {
  padding: 0;
  padding-left: 20px;
}
@media (min-height: 1080px) {
  .container {
    height: 100%;
    max-height: calc(100% - 275px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .containerFormasDePago {
    height: 100%;
  }
  .colContainerFormaDePagoTarjeta {
    padding: 0;
    padding-top: 50px;
  }
  .colFormaDePagTarjeta {
    padding-top: 50px;
  }
  .rowContainerButon {
    width: 100%;
  }
  .colContainerPagoCitaButton {
    justify-content: center;
  }
  .colContainerPrecio {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .colContainerPagoCitaButton {
    padding-top: 40px;
  }
  .container {
    padding-top: 100px;
  }
}

@media (max-width: 575px) {
  .colContainerPrecio {
    justify-content: center;
  }
  .colContainerButton {
    display: flex;
    justify-content: center;
    padding-top: 0;
  }
}
@media (max-width: 443px) {
  .colFormaDePagoTarjeta {
    padding: 0;
  }
  .colContainerFormaDePago {
    padding: 0;
  }
}
@media (max-width: 400px) {
  .container {
    padding-top: 0;
  }
}
