html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

input,
textarea {
  padding-left: 5px;
}

.containerBienvenido {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  min-height: 300px;
}

.bienvenidoContainer {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0;
}

.rowContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.colContainer {
}

.rowTitle {
  /* height: 10%; */
}
.colTitle {
  /* background-color: green; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.2em;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00788a;
}

.rowContainerFormulario {
  /* background-color: green; */
  height: 70%;
}
.colContainerFormulario {
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  /* background-color: orange; */
  width: 70%;
  /* height: 90%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rowContainerItemFormulario {
  /* background-color: red; */
  width: 100%;
  justify-content: center;
}

.rowContainerItemFormulario button {
  margin-top: 4%;
}

.colContainerItemFormulario_Title {
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  margin-top: 6px;
}

.colContainerItemFormulario {
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 6px;
  margin-top: 6px; */
}

.colContainerItemFormulario_Title label {
  margin-bottom: 0;
  display: block;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00788a;
}

.colContainerItemFormulario textarea,
select,
input {
  resize: none;
  overflow: hidden;
  width: 100%;
  border: 2px solid #ffce00;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: #00788a;
  font-size: 14px;
  font-weight: 600;
}

.colContainerItemFormulario textarea:focus-visible,
select:focus-visible,
input:focus-visible {
  border: 2px solid #ffce00;
  outline: none;
}

.rowButtonContinuar {
  /* background-color: red; */
  /* height: 2.5rem; */
  width: 100%;
  margin-top: 20px;
}

.colButtonContinuar {
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.colContainerButton__button,
.colContainerButton__button:focus {
  height: 50px;
  width: 100%;
  max-width: 244px;
  background-color: #ffd83d;
  color: #00788a;
  border: none;
  border-radius: 12.5px;
  cursor: pointer;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  outline: none;
}

.colContainerButton__button span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffcd00;
}
.colContainerButton__button span svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.colContainerSpanBoton {
  padding: 0;
  padding-left: 15%;
}
