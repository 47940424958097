.BotonGeneralActivo a {
  color: #10798b;
  text-decoration: none;
}

.background_azul {
  /* background-color: #00748c;
  color: #fff; */
  background-color: #fff;
  color: #00748c;
}

.background_amarillo {
  background-color: #ffd83d;
  color: #10798b;
}

.BotonGeneralActivo span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffcd00;
}
.BotonGeneralActivo span svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
.BotonGeneralInactivo span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.BotonGeneralInactivo span svg {
  width: 20px;
  height: 20px;
  fill: #2a4857;
}

.buttonContainerParams {
}
.rowContainerParams {
}
.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.colContainerSpanBoton {
  padding: 0;
  padding-left: 15%;
}

.BotonGeneralSeleccionado,
.BotonGeneralActivo,
.BotonGeneralActivo:focus {
  position: relative;
  height: 50px;
  width: 100%;
  max-width: 244px;
  border: none;
  border-radius: 12.5px;
  cursor: pointer;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  outline: none;
}

.BotonGeneralActivoRedondo,
.BotonGeneralActivoRedondo:focus {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  background-color: #fff;
  color: #00748c;
  outline: none;
  border: none;
}

.BotonGeneralSeleccionadoRedondo,
.BotonGeneralSeleccionadoRedondo:focus {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  font-family: DINNextRoundedLTPro, sans-serif;
  background-color: #fff;
  color: #00748c;
  outline: none;
  border: none;
  border: 1px solid #ffcd00;
}

.BotonGeneralSeleccionadoRedondo::after {
  position: absolute;
  top: 0;
  right: 25px;
  width: 25px;
  height: 25px;
  content: url("../../images/svg/general__accept-path.svg");
  border-radius: 50%;
  background-color: #ffcd00;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BotonGeneralSeleccionado,
.BotonGeneralActivo:hover {
  background-color: #00748c;
  color: #fff;
}
.BotonGeneralActivo:hover span {
  background-color: transparent;
}

.BotonGeneralInactivoRedondo {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  font-family: DINNextRoundedLTPro, sans-serif;
  background-color: #fff;
  color: #00748c;
  outline: none;
  border: none;
}
.BotonGeneralInactivo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  width: 100%;
  max-width: 244px;
  background-color: #dddee0;
  border: none;
  border-radius: 12.5px;
  color: #2a4857;
  font-size: 14px;
  /* line-height: 19px;
  letter-spacing: 0.5px; */
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
}
.BotonGeneralActivo_blanco {
  width: 100%;
  max-width: 350px;
  height: 50px;
  background-color: #ffd83d;
  border: none;
  border-radius: 12.5px;
  cursor: pointer;
  color: #00788a;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 13px;
  text-transform: uppercase;
}
.BotonGeneralActivo_blancoPresencial {
  width: 100%;
  max-width: 350px;
  height: 50px;
  background-color: #ffd83d;
  border: none;
  border-radius: 12.5px;
  cursor: pointer;
  color: #00788a;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 13px;
  text-transform: uppercase;
}

.BotonGeneralActivo_blanco a {
  color: inherit;
}
.BotonGeneralActivo_blancoPresencial a {
  color: inherit;
}
/* Extra- Small devices verticales (landscape phones, 576px and up) */
@media (max-width: 443px) {
  .BotonGeneralSeleccionadoRedondo::after {
    width: 20px !important;
    height: 20px !important;
    right: 15px;
  }
}
/* Small devices  horizontales (landscape phones, 576px and up) */
@media (max-width: 767px) {
  .BotonGeneralActivo_blanco {
    max-width: 100%;
  }
  .BotonGeneralActivo_blancoPresencial {
    max-width: 100%;
  }
}

@media (max-width: 750px) {
  .BotonGeneralActivoRedondo {
    width: 100px;
    height: 100px;
  }
  .BotonGeneralInactivoRedondo {
    width: 100px;
    height: 100px;
  }
  .BotonGeneralSeleccionadoRedondo {
    width: 100px;
    height: 100px;
  }
}

@media(min-width:1200px){
  .BotonGeneralActivo_blanco{
    max-width: 97%;
  }
}