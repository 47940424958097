/* //! Aqui comienzan los nuevos estilos para sucursales cercanas */
.containerSucursales {
  background-color: rgba(102, 113, 128, 0.06);
  padding-top: 46px;
  padding-bottom: 50px;
}
.rowContainerTitleButtonDropdown {
  padding-bottom: 40px;
}
.colContainerTitle__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 28px;
  color: #00606e;
}
.DropdownClinicas,
.DropdownClinicas:focus {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 1px solid #00788a;
  color: #00788a;
  border-radius: 12.5px;
  width: 288px;
  height: 48px;
  font-family: DINNextRoundedLTPro, sans-serif;
  font-size: 16px;
  outline: none;
}
.DropdownClinicas:hover {
  color: #00788a;
}
.rowContainerImgMapInfo {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  border-radius: 20px;
  border: 3px solid #fff;
  /* min-height: 450px; */
  width: 85%;
  margin: 0 auto;
  padding: 10px 0;
  max-width: 875px;
}
.colContainerTitleBeforeMap {
  height: 40%;
}
.colContainerTitleBeforeMap__title {
  width: 100%;
  max-width: 397px;
  text-align: center;
  margin: auto;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-display: auto;
  font-size: 28px;
  color: #00606e;
}
.colContainerTitleBeforeMap__title span {
  font-family: DINNextRoundedLTPro, sans-serif;
}
.colContainerImgBeforeMap {
  height: 60%;
}
.colContainerComoLlego {
  height: 100%;
  padding: 0 auto;
}
.colContainerComoLlegoPantallasPequeñas {
  height: 100%;
  padding: 0;
  display: none;
}
.colContainerComoLlegoTitle {
  display: none;
}
.colContainerComoLlegoTitle__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 23px;
  color: #00606e;
  margin: 0;
}
.colContainerComoLlegoTitle__borde {
  border: 1px solid #ffd83d;
  width: 67px;
  margin-bottom: 40px;
}
.colContainerMapInfo {
  height: 100%;
}
.rowMapInfo {
  width: 100%;
  height: fit-content;
}
.colContainerMap {
  width: 100%;
  height: 65%;
  min-height: 200px;
}
.colContainerInfoClinic {
  width: 100%;
  height: 35%;
}
.colContainerTitleClinica__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
  color: #00606e;
}
.colContainerTitleDireccion__title {
  margin: 0;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 15px;
  color: #00606e;
  padding-left: 10px;
}
.colContainerDireccion__p {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 15px;
  color: #00606e;
}
.colContainerTitleHorarios {
  margin: 0;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 15px;
  color: #00606e;
  margin: 0;
  padding-left: 10px;
}
.colContainerHorariosLunesAViernes__p {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 15px;
  color: #00606e;
}
.colContainerHorariosSabados {
  margin-top: -5px;
}
.colContainerHorariosSabados__p {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 15px;
  color: #00606e;
}
.rowContainerButton {
  margin-top: 20px;
  width: 100%;
}
.rowContainerButtonsRegresarContinuar {
  width: 100%;
}
.rowContainerButtonsRegresarContinuar__sinBoton {
  display: flex;
  justify-content: center;
}
.colContainerRegresar {
  padding: 10px;
  padding-right: 25px;
  display: flex;
  justify-content: flex-end;
}
.colContainerButtons__regresar,
.colContainerButtons__regresar:focus {
  width: 100%;
  max-width: 270px;
  height: 50px;
  background-color: rgba(102, 113, 128, 0.06);
  border-radius: 12.5px;
  outline: none;
  border: 1px solid #00788a;
  padding: 10px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
  color: #00788a;
}
.colContainerContinuar {
  padding: 10px;
  padding-left: 25px;
  display: flex;
  justify-content: flex-start;
}
.colContainerContinuar__centrado {
  justify-content: center;
}
.colContainerButtons__continuar,
.colContainerButtons__continuar:focus {
  width: 100%;
  max-width: 244px;
  height: 50px;
  background-color: #fdcd2f;
  color: #00748c;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
  border: none;
  border-radius: 10px;
  outline: none;
}
.colContainerButtons__continuar:hover {
  background-color: #00748c;
  color: #fff;
}
.colContainerButtons__continuar:hover span {
  background-color: transparent;
}
.colContainerButtons__continuar:hover .colContainerButtons__svg {
  fill: #fff;
}
.colContainerButtons__continuar:disabled {
  cursor: pointer;
  background-color: #dddee0;
  color: #2a4857;
}
.colContainerButtons__continuar:disabled:hover {
  color: #2a4857;
}
.colContainerButtons__continuar:disabled .colContainerButtons__span {
  background-color: #dddee0;
}
.colContainerButtons__continuar:disabled .colContainerButtons__svg {
  fill: #2a4857;
}
.colContainerButtons__continuar:disabled:hover .colContainerButtons__svg {
  fill: #2a4857;
}
.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.colContainerSpanBoton {
  padding: 0;
}
.colContainerButtons__span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fac61b;
}
.colContainerButtons__svg {
  width: 15px;
  height: 15px;
  fill: #fff;
}
@media (max-width: 400px) {
  .containerSucursales {
    padding-top: 0 !important;
  }
  .colContainerTitle__title {
    font-size: 17px !important;
  }
  .colContainerTitleBeforeMap__title {
    font-size: 15px !important;
  }
  .colContainerDireccion {
    padding: 0 5px;
    padding-left: 23px;
  }
  .rowContainerHorariosClinicas {
    margin-top: 12px;
    margin-left: auto;
  }
  .colContainerHorariosLunesAViernes {
    padding: 0 5px;
  }
  .colContainerHorariosSabados {
    padding: 0 5px;
  }
}
@media (max-width: 443px) {
  .colContainerComoLlegoTitle__title {
    font-size: 18px;
  }
  .rowContainerButton {
    width: auto;
  }
  .colContainerButtons__regresar {
    font-size: 11px;
  }
  .colContainerButtons__continuar {
    padding-left: 20px;
  }
  .colContainerButtons__span {
    margin-left: 10px;
  }
}
@media (max-width: 576px) {
  .colContainerButtons__regresar {
    width: 180px;
    font-size: 11px;
  }
  .colContainerButtons__continuar {
    width: 180px;
  }
}
@media (max-width: 767px) {
  .colContainerRegresar {
    justify-content: center;
    padding: 10px;
  }
  .colContainerContinuar {
    justify-content: center;
    padding: 10px;
  }
}
@media (max-width: 768px) {
  .colContainerTitle__title {
    font-size: 18px;
  }
  .colContainerTitleBeforeMap__title {
    font-size: 18px;
    width: 100%;
  }
  .colContainerImgBeforeMap__img {
    width: 100%;
  }
  .colContainerButtons__regresar {
    width: 220px;
    font-size: 11px;
  }
  .colContainerButtons__continuar {
    width: 220px;
    font-size: 11px;
  }
}
@media (max-width: 661px) {
  .colContainerComoLlego {
    padding: 0;
    display: none;
  }
  .colContainerComoLlegoPantallasPequeñas {
    display: flex;
  }
  .colContainerComoLlegoTitle {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 994px) {
  .containerSucursales {
    padding-top: 0;
  }
  .rowContainerButtonsRegresarContinuar {
    width: 70%;
  }
}
@media (max-width: 992px) {
  .containerSucursales {
    padding-top: 70px;
  }
}
@media (min-height: 1200px) {
  .containerSucursales {
    background-color: rgba(102, 113, 128, 0.06);
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: calc(100% - 275px);
  }
}
/* //!  Aqui terminan los nuevos estilos para sucursales cercanas*/

/* //!Comienzan Estilos para DropDownHorarios */

.DropdownHorarios {
  border: 2px solid #00788a;
  border-radius: 12.5px;
  height: 48px;
  font-family: DINNextRoundedLTPro, sans-serif;
  font-size: 16px;
  color: #808080;
  width: 224px;
}
.DropdownHorarios:hover {
  color: #808080;
}
.ItemHorarios {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: DINNextRoundedLTPro, sans-serif;
  text-align: center;
  color: #808080;
}

.ItemHorarios:hover {
  background-color: #00748c;
  color: #fff;
}
/* //!Terminan Estilos para DropDownHorarios */
