html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.containerBienvenido {
  width: 100%;
  height: 120%;
  padding: 0;
  margin: 0;
  min-height: 300px;
}
.bienvenidoContainer {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0;
}
.rowContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.colContantainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.rowContainerBienvenido {
  width: 100%;
  height: 100%;
  margin: 0;
}
.colContainerTitleSubTitle {
  background-color: #00788a;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rowContainerTitleSubtitle {
  margin: 0;
  padding: 0;
}
.colContainerTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.colContainerTitle__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 40px;
  line-height: 1.25;
  color: #ffffff;
  margin: 0;
}
.colContainerSubTitle {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.colContainerSubTitle__subTitle {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  font-size: 18px;
  color: #ffffff;
  margin: 0;
}
.inputNombre__GDLV {
  height: 45px;
}
.inputNombre__GDLV::placeholder {
  color: gray;
  font-size: 14px;
}
.etiquetaTitulo__GDLV {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 20px;
  color: #fff;
  margin: 0;
  display: block;
  justify-content: center;
  align-items: center;
}
.etiquetaTitulo__GDLV2 {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 20px;
  color: #fff;
  margin: 0 0 0 3em;
  display: block;
  justify-content: center;
  align-items: center;
}
.etiquetaTitulo__GDLVOPTO {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 20px;
  color: #fff;
  margin: 0 0 0 8px;
  display: block;
  justify-content: center;
  align-items: center;
}
.etiquetaTitulo__GDLV3 {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 20px;
  color: #fff;
  margin: 0 50px 0 0;
  display: block;
  justify-content: center;
  align-items: center;
}
.etiquetaSubTitulo__GDLV {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  font-size: 18px;
  color: #ffd83d;
  margin: 1px 0 1em 0;
  display: flex;
  justify-content: center;
}
.etiquetaSubTitulo__GDLV2 {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  font-size: 18px;
  color: #ffd83d;
  margin: 1px 40px 1em 0;
  display: flex;
  justify-content: center;
}
.etiquetaSubTitulo__GDLV3 {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  font-size: 18px;
  color: #ffd83d;
  margin: 1px 0 1em -5em;
  display: flex;
  justify-content: center;
}
.subTitulo_Campaign {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  font-size: 18px;
  color: #ffd83d;
  margin: 0 0 0 5px;
  display: flex;
  justify-content: center;
}
.etiquetaNoExiste {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
  color: #ffd83d;
  margin: 25px 0 0 0;
}
.etiquetaNoExiste2 {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
  color: #ffd83d;
  margin: 25px 0 0 0;
}
.etiquetaNoExisteSub {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 16px;
  color: #fff;
  margin: 0;
  justify-content: center;
  align-items: center;
}
.etiquetaNoExisteSub2 {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 16px;
  color: #fff;
  margin: 0 3em 0 0;
  justify-content: center;
  align-items: center;
}
.colMensaje_GDLV{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 10px 0 0 0;
}
.colContainerTitle__GDLV {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
  color: #fff;
  margin: 0 0 0 2em;
}
.tituloCampaign {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
  color: #fff;
  margin: 0 0 0 0;
}
.colContainerListGuardianes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  max-height: 100px;
}
.colContainerListOpto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  max-height: 100px;
}
.colContainerCampaign {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100px;
  margin: 0 0 1em 0;
}
.colContainerListGuardianes2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.colContainerListGuardianes3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 40px;
}

.colContainerListGuardianes h5 {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  color: #fff;
}
.colContainerListGuardianes select {
  width: auto;
  border-radius: 12px;
  height: 35px;
  outline: none;
  border: none;
  box-shadow: 4px 4px 12px -5px;
}
.colContainerListGuardianes select option {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.colContainerListOpto h5 {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  color: #fff;
}
.colContainerListOpto select {
  width: auto;
  border-radius: 12px;
  height: 35px;
  outline: none;
  border: none;
  box-shadow: 4px 4px 12px -5px;
}
.colContainerListOpto select option {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.colContainerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}
.colContainerButton__button,
.colContainerButton__button:focus {
  height: 50px;
  width: 100%;
  max-width: 244px;
  min-width: 200px;
  background-color: #ffd83d;
  color: #00788a;
  border: none;
  border-radius: 12.5px;
  cursor: pointer;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  outline: none;
}
.colContainerButton__GDLV,
.colContainerButton__GDLV:focus {
  height: 50px;
  width: 50%;
  max-width: 244px;
  min-width: 200px;
  background-color: #ffd83d;
  color: #00788a;
  border: none;
  border-radius: 12.5px;
  cursor: pointer;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 4px;
}
.colContainerRegresar__GDLV,
.colContainerRegresar__GDLV:focus {
  height: 40px;
  width: 100%;
  max-width: 120px;
  background-color: #fff;
  color: #00788a;
  border: 2px solid #ffcd00;
  border-radius: 12.5px;
  cursor: pointer;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.colContainerButton__GDLV2,
.colContainerButton__GDLV2:focus {
  height: 50px;
  width: 100%;
  max-width: 244px;
  min-width: 200px;
  background-color: #ffd83d;
  color: #00788a;
  border: none;
  border-radius: 12.5px;
  cursor: pointer;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 2px;
}
.colContainerButton__OPTO,
.colContainerButton__OPTO:focus {
  height: 50px;
  width: 100%;
  max-width: 150px;
  min-width: 150px;
  background-color: #ffd83d;
  color: #00788a;
  border: none;
  border-radius: 12.5px;
  cursor: pointer;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.colContainerSpanBoton {
  padding: 0;
  padding-left: 15%;
}
.colContainerButton__button span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffcd00;
}
.colContainerButton__button span svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
.colContainerButton__GDLV span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffcd00;
}
.colContainerButton__GDLV span svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
.colContainerButton__GDLV2 span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffcd00;
}
.colContainerButton__GDLV2 span svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
.colContainerButton__OPTO span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffcd00;
}
.colContainerButton__OPTO span svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
.containerButtons {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rowContainerButtonsGDLV {
  width: 100%;
  max-width: 70%;
  justify-content: center;
  margin-top: 10px;
}

.rowContainerRegresar {
  width: 100%;
  max-width: 100%;
  justify-content: left;
  margin: 3em 15em 0 0;
}

.colContainerButtonGDLV {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 442px) {
  .colContainerTitle__title {
    font-size: 32px;
  }
  .colContainerButton {
    padding-top: 30px;
  }
}

@media (max-width: 991px) {
  .colContainerButtonGDLV {
    margin-top: 10px;
    padding-top: 0;
  }
  .rowContainerButtonsGDLV {
    margin-top: 0px;
  }
  .colContainerButton__GDLV {
    margin: 0 0 0 3px;
  }
  .colContainerButton__GDLV2 {
    margin: 0 0 0 2px;
  }
  .colContainerButton__OPTO {
    margin: 0 0 0 40px;
  }
  .colContainerListOpto {
    margin: 0 0 0 20px;
  }
  .containerBienvenido{
    height: 170%;
  }
  .etiquetaTitulo__GDLV {
    margin: 0 1em 0 0;
  }
  .rowContainerRegresar {
    margin: 1em 0 0 0;
  }
  .colContainerButton {
    margin: 0 0 1em 0;
  }
  .etiquetaNoExiste {
    margin: 25px 0 0 42px;
  }
  .etiquetaNoExiste2 {
    margin: 25px 0 0 30px;
  }
  .colMensaje_GDLV{
    margin: 10px 0 0 48px;
  }
}
