.container {
	width: 100%;
	padding: 0;
	background-color: rgba(102, 113, 128, 0.06);
}

.rowContainerIncluye {
	color: #00748c;
}

.rowContainerIncluye__title {
	font-family: DINNextRoundedLTPro, sans-serif;
	font-size: 1.7rem;
}

.rowContainerIncluye__ul {
	padding: 0;
	text-align: center;
	list-style: none;
	font-size: 1.2rem;
	width: 60%;
	font-family: DINNextRoundedLTPro-Regular, sans-serif;
}

.rowContainerRestricciones__li,
.rowContainerIncluye__li {
	color: #00748c;
}
.datosAdicionalesSeparacion{
	margin-top: 15px;
}
.rowContainerTextInputEdad {
}
.colContainerLabelEdad {
	justify-content: center;
	align-items: center;
	display: flex;
}
.colContainerLabelEdad__label {
	padding: 0;
	text-align: center;
	list-style: none;
	font-size: 1.2rem;
	width: 60%;
	font-family: DINNextRoundedLTPro-Regular, sans-serif;
	color: #00748c;
}
.colContainerTextInputEdad {
	justify-content: center;
	align-items: center;
	display: flex;
}
.colContainerTextInputEdad__TextInputEdad {
	width: 255px;
	max-width: 255px;
	height: 56px;
	border: 2px solid #00788a;
	border-radius: 12.5px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	outline: none;
	text-align: center;
}
.colContainerTextInputEdad__TextInputEdad:focus {
	color: #667180 !important;
	font-family: DINNextRoundedLTPro-Medium, sans-serif;
	background-color: transparent;
	outline: none;
	text-align: center;
}
.rowContainerButtons__promo2x1 {
	position: relative;
	height: 48px;
	width: 288px;
	color: #00748c;
	background-color: #fdcd2f;
	border: none;
	border-top-left-radius: 70px;
	border-top-right-radius: 70px;
	border-bottom-left-radius: 70px;
	border-bottom-right-radius: 70px;
	cursor: pointer;
	font-weight: bold;
	font-size: 1rem;
	line-height: 19px;
	letter-spacing: 0.5px;
	font-family: DINNextRoundedLTPro, sans-serif;
}
.rowContainerButtons__promo2x1:disabled {
	cursor: pointer;
	background-color: #dddee0;
	color: #2a4857;
}

.rowContainerButtons__citaNormal {
	position: relative;
	height: 48px;
	width: 288px;
	color: #a8a8a8;
	background-color: #fff;
	border: 1px solid #00748c;
	border-top-left-radius: 70px;
	border-top-right-radius: 70px;
	border-bottom-left-radius: 70px;
	border-bottom-right-radius: 70px;
	cursor: pointer;
	font-weight: bold;
	font-size: 1rem;
	line-height: 19px;
	letter-spacing: 0.5px;
	font-family: DINNextRoundedLTPro, sans-serif;
}

.rowContainerRestricciones__h3 {
	font-family: DINNextRoundedLTPro-Regular, sans-serif;
	font-size: 1.5rem;
	color: #00748c;
}

.rowContainerRestricciones__ul {
	list-style: disc;
	font-family: DINNextRoundedLTPro-Regular, sans-serif;
	font-size: 1.15rem;
	color: #00748c;
	list-style-position: inside;
}

.footer {
	height: 7vmin;
	background-color: #00748c;
	width: 100%;
	margin: 0;
}

@media (max-width: 992px) {
	.rowContainerIncluye__title {
		font-size: 1.4rem;
	}

	.rowContainerIncluye__ul {
		font-size: 1rem;
		width: 70%;
	}

	.rowContainerRestricciones__ul {
		width: 90%;
	}
}

@media (max-width: 991px) {
	.rowContainerButtons__promo2x1 {
		margin-bottom: 35px;
	}
}

@media (max-width: 768px) {
	.rowContainerIncluye__title {
		font-size: 1.1rem;
	}

	.rowContainerRestricciones__h3 {
		font-size: 1.3rem;
	}

	.rowContainerRestricciones__ul {
		font-size: 1rem;
	}
}

@media (max-width: 576px) {
	.rowContainerButtons__colButtonCitaNormal {
		padding-top: 0 !important;
	}
}

@media (max-width: 443px) {
	.rowContainerIncluye__title {
		font-size: 1rem;
	}

	.rowContainerIncluye__ul {
		width: 95%;
	}

	.rowContainerRestricciones__h3 {
		font-size: 1.1rem;
	}

	.rowContainerRestricciones__ul {
		font-size: 0.7rem;
	}
}
