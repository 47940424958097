html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.containerBienvenido {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  min-height: 300px;
}
.bienvenidoContainer {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0;
}
.rowContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.colContantainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.rowContainerBienvenido {
  width: 100%;
  height: 100%;
  margin: 0;
}
.colContainerTitleSubTitle {
  background-color: #C1DCE4;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rowContainerTitleSubtitle {
  margin: 0;
  padding: 0;
}
.colContainerTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.colContainerTitle__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 40px;
  line-height: 1.25;
  color: #06758C;
  margin: 0;
}
.colContainerSubTitle {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.colContainerSubTitle__subTitle {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  font-size: 18px;
  color: #06758C;
  margin: 0;
}
.colContainerListCI {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  max-height: 100px;
}
.colContainerListCI h5 {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  color: #06758C;
}
.colContainerListCI select {
  border-radius: 12px;
  height: 35px;
  outline: none;
  border: none;
  box-shadow: 4px 4px 12px -5px;
  width: auto;
}
.colContainerListCI select option {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.colContainerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}
.colContainerButton__button,
.colContainerButton__button:focus {
  height: 50px;
  width: 100%;
  max-width: 244px;
  background-color: #ffd83d;
  color: #00788a;
  border: none;
  border-radius: 12.5px;
  cursor: pointer;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  outline: none;
}
.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.colContainerSpanBoton {
  padding: 0;
  padding-left: 15%;
}
.colContainerButton__button span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffcd00;
}
.colContainerButton__button span svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.rowContainerButtonsGDLV {
  width: 100%;
  max-width: 70%;
}

.colContainerButtonGDLV {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 442px) {
  .colContainerTitle__title {
    font-size: 32px;
  }
  .colContainerButton {
    padding-top: 30px;
  }
}

@media (max-width: 991px) {
  .colContainerButtonGDLV {
    margin-top: 10px;
    padding-top: 0;
  }
}
