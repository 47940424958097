.container {
  width: 100%;
  height: 100%;
  padding: 0;
}

.rowContainerIncluye {
  color: #00748c;
}

.rowContainerIncluye__title {
  font-family: DINNextRoundedLTPro, sans-serif;
  font-size: 1.7rem;
}

.rowContainerIncluye__ul {
  padding: 0;
  text-align: center;
  list-style: none;
  font-size: 1.2rem;
  width: 60%;
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
}

.rowContainerRestricciones__li,
.rowContainerIncluye__li {
  color: #00748c;
}

.rowContainerButtons__promo2x1 {
  position: relative;
  height: 48px;
  width: 288px;
  color: #00748c;
  background-color: #fdcd2f;
  border: none;
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
  letter-spacing: 0.5px;
  font-family: DINNextRoundedLTPro, sans-serif;
}

.rowContainerButtons__citaNormal {
  position: relative;
  height: 48px;
  width: 288px;
  color: #a8a8a8;
  background-color: #fff;
  border: 1px solid #00748c;
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
  letter-spacing: 0.5px;
  font-family: DINNextRoundedLTPro, sans-serif;
}

.rowContainerRestricciones__h3 {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  font-size: 1.5rem;
  color: #00748c;
}

.rowContainerRestricciones__ul {
  list-style: disc;
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  font-size: 1.15rem;
  color: #00748c;
  list-style-position: inside;
}

.footer {
  height: 13vmin;
  background-color: #00748c;
  width: 100%;
  margin: 0;
}

@media (max-width: 992px) {
  .rowContainerIncluye__title {
    font-size: 1.4rem;
  }

  .rowContainerIncluye__ul {
    font-size: 1rem;
    width: 70%;
  }

  .rowContainerRestricciones__ul {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .rowContainerIncluye__title {
    font-size: 1.1rem;
  }

  .rowContainerRestricciones__h3 {
    font-size: 1.3rem;
  }

  .rowContainerRestricciones__ul {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .rowContainerButtons__colButtonCitaNormal {
    margin-top: 5%;
  }
}

@media (max-width: 443px) {
  .rowContainerIncluye__title {
    font-size: 1rem;
  }

  .rowContainerIncluye__ul {
    width: 95%;
  }

  .rowContainerRestricciones__h3 {
    font-size: 1.1rem;
  }

  .rowContainerRestricciones__ul {
    font-size: 0.7rem;
  }
}
