.containerSignosVitales {
  width: 100%;
  height: 100%;
  /* background-color: red; */
}

.rowContainerGraph {
  padding: 0;
  margin: 0;
  /* background-color: blue; */
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.divContainerGraph {
  padding: 0;
  margin: 0;
  /* background-color: purple; */
  height: 100%;
  width: 100%;
}

.colContainerGraph {
  /* background-color: yellow; */
  width: 100%;
  height: 100%;
}

.rowContainerSelectOptions {
  margin: 0;
  padding: 0;
  /* background-color: green; */
  height: 5%;
}

.colContainerFontText {
  margin: 0;
  padding: 0;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.colContainerSelectOptions {
  margin: 0;
  padding: 0;
  /* background-color: orange; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* height: 30%;
  max-height: 30%; */
}

.rowContainerMetrics {
  margin: 0;
  padding: 0;
  /* background-color: blueviolet; */
  width: 100%;
  height: 100%;
}

.colContainerMetrics {
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.fontMetrics {
  font-size: 15px;
  color: #00788a;
  font-weight: bold;
}

.fontText {
  margin: 0;
  padding: 0;
  color: #00788a;
  font-weight: bold;
}

.selectStyles {
  width: 50%;
  /* height: 30%; */
}

.rotate90Degrees {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.colContainerGraphPicture {
  /* background-color: royalblue; */
  margin: 0;
  padding: 0;
}
