
.toastShow {
  display: flex;
  text-align: center;
  justify-content: center;
}

.toastComponent {
  position: fixed;
  z-index: 2;
  border-radius: 10px;
  top: 30px;
}

.toastHide {
  display: none;
}

.toastBody {
  font-size: 1.5rem;
  color: #00778b;
  font-family: DINNextRoundedLTPro, sans-serif;
}

/* Extra- Small devices verticales (landscape phones, 576px and up) */
@media (min-width: 300px) and (max-width: 400px) {
  .col_imgLogo {
    padding-left: 7.5%;
  }

  .div_CirculoGeneralHS {
    width: 110px;
  }

  .div_CirculoH1HS {
    position: relative;
    background-color: #00778b;
    padding-top: 1%;
    padding-left: 2.5%;
    height: 55px;
  }
}

@media (min-width: 401px) and (max-width: 575px) {
  .col_imgLogo {
    padding-left: 7.5%;
  }

  .div_CirculoGeneralHS {
    width: 110px;
  }

  .div_CirculoH1HS {
    position: relative;
    background-color: #00778b;
    padding-top: 1%;
    padding-left: 2.5%;
    height: 55px;
  }
}

/* Small devices  horizontales (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 680px) {
  .col_imgLogo {
    padding-left: 6.5%;
  }

  .div_CirculoGeneralHS {
    width: 110px;
  }

  .div_CirculoH1HS {
    position: relative;
    background-color: #00778b;
    padding-top: 1%;
    padding-left: 2.5%;
    height: 59px;
  }
}

@media (min-width: 681px) and (max-width: 767px) {
  .col_imgLogo {
    padding-left: 6.5%;
  }

  .div_CirculoGeneralHS {
    width: 110px;
  }

  .div_CirculoH1HS {
    position: relative;
    background-color: #00778b;
    padding-top: 1%;
    padding-left: 2.5%;
    height: 59px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .col_imgLogo {
    padding-left: 7%;
  }

  .div_CirculoH1HS {
    background-color: #00778b;
    padding-top: 0.7%;
    padding-bottom: 0.7%;
    padding-left: 2%;
  }

  .div_CirculoGeneralHS {
    width: 130px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .div_CirculoH1HS {
    position: relative;
    background-color: #00778b;
    padding-top: 0.7%;
    padding-bottom: 0.7%;
    padding-left: 2%;
  }

  .div_CirculoGeneralHS {
    width: 130px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .col_imgLogo {
    padding-left: 5.5%;
  }

  .div_CirculoH1HS {
    position: relative;
    background-color: #00778b;
    padding-top: 0.7%;
    padding-bottom: 0.7%;
    padding-left: 2%;
  }

  .div_CirculoGeneralHS {
    width: 166px;
  }
}

/* Extra large super devices (large desktops, 1500px and up) */
@media (min-width: 1500px) {
  .col_imgLogo {
    padding-left: 5.5%;
  }

  .div_CirculoH1HS {
    position: relative;
    background-color: #00778b;
    padding-top: 0.7%;
    padding-bottom: 0.7%;
    padding-left: 2%;
  }

  .div_CirculoGeneralHS {
    width: 166px;
  }
}
