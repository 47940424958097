/* //! Aqui comienzan los nuevos estilos */

.containerCancelar {
  background-color: rgba(102, 113, 128, 0.06);
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancelarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10% 0;
}
.rowContainer {
  width: 100%;
  height: 100%;
}
.colContainer {
}
.rowInfoContainer {
}
.colContainerIcon {
  padding: 20px 0;
}
.colContainerTitle {
  padding-bottom: 20px;
}
.colContainerTitle__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 40px;
  color: #00606e;
  text-align: center;
  margin: 0;
  /* max-width: 245px; */
}
.colContainerSubTitle {
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
}
.colContainerSubTitle__subtitle {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 26px;
  color: #00606e;
  text-align: center;
  margin: 0;
  max-width: 499px;
}
.colContainerButton {
}
.rowContainerButton {
  width: 65%;
}
.colContainerButtonAgendar {
  display: flex;
  justify-content: center;
}

.colContainerButton__button {
  width: 100%;
  max-width: 244px;
  height: 50px;
  background-color: #fdcd2f;
  color: #00748c;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
  border: none;
  border-radius: 10px;
  outline: none;
}

.buttonContainerParams {
}
.rowContainerParams {
}
.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.colContainerSpanBoton {
  padding: 0;
}

.colContainerButton__button:hover {
  background-color: #00748c;
  color: #fff;
}
.colContainerButton__button:hover span {
  background-color: transparent;
}
.colContainerButtons__button:hover .colContainerButtons__svg {
  fill: #fff;
}
.colContainerButton__span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 15px;
  background-color: #fac61b;
}
.colContainerButton__svg {
  width: 15px;
  height: 15px;
  fill: #fff;
}

/* //! Aqui terminan los nuevos estilos */

.background {
  background-color: #00778b#00778b;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

.logo {
  width: 112px;
  height: 80px;

  /* border: 2px solid #0ff; */
}

.Separador1 {
  height: 45px;
  width: 100%;
}

.Separador2 {
  width: 70%;
}

.etiqueta {
  color: #fff;
  font-size: 1.2em;
  font-family: ChivoRegular, sans-serif;

  /* border: 2px solid #0ff; */
}

.informacion {
  color: #fff;
  font-size: 1.6em;
  font-family: ChivoRegular, sans-serif;
  padding-top: 2%;
  padding-bottom: 6%;

  /* border: 2px solid #00f; */
}

.infoExtra {
  border-radius: 15px;
  background-color: #eff7ff;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
}

.infoHorizontal {
  border-radius: 15px;
  background-color: #eff7ff;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  display: flex;
  flex-direction: row;
}

.nota {
  font-family: ChivoRegular, sans-serif;
  font-size: 1.2em;
  color: #53585f;
  text-align: center;
  padding-bottom: 15px;
}

.nota2 {
  font-family: ChivoRegular, sans-serif;
  font-size: 1.2em;
  color: #53585f;
  text-align: left;
  padding-top: 8px;
}

.icon {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  margin-left: 45%;

  /* border: 2px solid #0ff; */
}

.icon2 {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.mapa {
  width: 100%;
  height: 450px;
}

.comoLlego {
  height: 500px;
}

.div_Boton_Cancel {
  position: relative;
  font-family: ChivoRegular, sans-serif;
  font-size: 1em;
  background-color: #fff;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  text-align: -webkit-center;

  /* height: 100px; */
}

.comprobante {
  font-family: ChivoBold, sans-serif;
  color: #ffcd00;
  font-size: 1.15em;
}

.contenedor {
  padding-left: 0;
  padding-right: 0;
}

.foto {
  width: 128px;
  height: 128px;

  /* border: 2px solid #0ff; */
}

.cabeceraHorizontal {
  display: flex;
  flex-direction: row;
}

.modal-content {
  min-height: -webkit-fill-available;
}

.img-modal {
  text-align: -webkit-center;
}

.text-modal {
  text-align: -webkit-center;
  font-family: ChivoRegular, sans-serif;
  font-size: 2.2rem;
  margin-left: 15%;
  margin-right: 15%;
}

.imagen-cancelacion {
  text-align: -webkit-center;
}

.logotipo-cancelacion {
  padding-left: 2rem;
}

/* Extra- Small devices verticales (landscape phones, 576px and up) */
@media (max-width: 400px) {
  .cancelarContainer {
    padding: 40% 0;
  }
}
@media (max-width: 443px) {
  .colContainerButton__button {
    padding-left: 15px !important;
    font-size: 13px;
    margin: 0;
  }
  .rowContainer {
    width: 100% !important;
  }
  .containerCancelar{
    height: auto;
    min-height: calc(100% - 206px);
  }
  .containerCancelar{
    height: 100%;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .botonesAlineacion_Cancel {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding-top: 20px;
  }

  .col_cabecera {
    display: flex;
    justify-content: center;
  }

  .col_imgCancelacion {
    display: flex;
    justify-content: center;
  }

  .col_logo {
    padding-left: 2%;
  }

  .col_logoSalauno {
    display: flex;
    justify-content: center;
    padding-top: 3%;
  }

  .col_phone {
    display: flex;
    justify-content: center;
  }

  .col_txtCancelacion {
    display: flex;
    justify-content: center;
    padding-top: 4%;
    padding-left: 19%;
    padding-right: 18%;
  }

  .col_txtLlamanos {
    display: flex;
    justify-content: center;
    padding-top: 2%;
  }

  .div_Boton_Cancel {
    position: relative;
    font-family: ChivoRegular, sans-serif;
    font-size: 1em;
    background-color: #fff;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    text-align: center;

    /* height: 100px; */
  }

  .phoneCancelacion {
    text-decoration: underline;
    font-family: DINNextRoundedLTPro, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: #10798b;
  }

  .phone-cancelacion2 {
    text-decoration: underline;
    font-family: DINNextRoundedLTPro, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: #10798b;
  }

  .responsableSanitarioCancelacion {
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
    color: #707070;
    font-family: ChivoRegular, sans-serif;
    padding-top: 5%;
    padding-bottom: 4.5%;
    padding-left: 12%;
    padding-right: 11%;
    text-align: center;
  }

  .row_btnSeccion {
    margin: 0;
  }

  .row_cabecera {
    background-color: #00778b;
    margin: 0;
  }

  .row_imgCancelacion {
    background-color: #00778b;
    margin: 0;
  }

  .row_Logo {
    background-color: #00778b;
    margin: 0;
  }

  .row_logoSalauno {
    margin: 0;
    display: none;
  }

  .row_phone {
    margin: 0;
    display: none;
  }

  .row_responsable {
    margin: 0;
  }

  .row_txtCancelacion {
    margin: 0;
    padding-top: 33%;
  }

  .textCancelacion {
    text-align: -webkit-center;
    font-family: ChivoRegular, sans-serif;
    font-size: 1.1rem;
    color: #707070;
    margin-bottom: 0;

    /* text-align: center; */
  }

  .row_txtLlamanos {
    margin: 0;
    display: none;
  }

  .titulo {
    color: #fff;
    font-size: 1.5em;
    font-family: ChivoRegular, sans-serif;
  }
}

@media (min-width: 401px) and (max-width: 575px) {
  .botonesAlineacion_Cancel {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding-top: 20px;
  }

  .col_cabecera {
    display: flex;
    justify-content: center;
  }

  .col_imgCancelacion {
    display: flex;
    justify-content: center;
  }

  .col_logo {
    padding-left: 2%;
  }

  .col_logoSalauno {
    display: flex;
    justify-content: center;
    padding-top: 3%;
  }

  .col_phone {
    display: flex;
    justify-content: center;
  }

  .col_txtCancelacion {
    display: flex;
    justify-content: center;
    padding-top: 4%;
    padding-left: 21%;
    padding-right: 21%;
  }

  .col_txtLlamanos {
    display: flex;
    justify-content: center;
    padding-top: 2%;
  }

  .div_Boton_Cancel {
    position: relative;
    font-family: ChivoRegular, sans-serif;
    font-size: 1em;
    background-color: #fff;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 8%;
    padding-bottom: 20px;
    width: 100%;
    text-align: center;

    /* height: 100px; */
  }

  .phoneCancelacion {
    text-decoration: underline;
    font-family: DINNextRoundedLTPro, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: #10798b;
  }

  .responsableSanitarioCancelacion {
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
    color: #707070;
    font-family: ChivoRegular, sans-serif;
    padding-top: 4%;
    padding-bottom: 0.5%;
    padding-left: 15%;
    padding-right: 14%;
  }

  .row_btnSeccion {
    margin: 0;
  }

  .row_cabecera {
    background-color: #00778b;
    margin: 0;
  }

  .row_imgCancelacion {
    background-color: #00778b;
    margin: 0;
  }

  .row_txtLlamanos {
    margin: 0;
    display: none;
  }

  .row_Logo {
    background-color: #00778b;
    margin: 0;
  }

  .row_logoSalauno {
    margin: 0;
    display: none;
  }

  .row_phone {
    margin: 0;
    display: none;
  }

  .row_responsable {
    margin: 0;
  }

  .row_txtCancelacion {
    margin: 0;
    padding-top: 20%;
  }

  .textCancelacion {
    font-family: ChivoRegular, sans-serif;
    font-size: 1.2rem;
    color: #707070;
    margin-bottom: 0;
    text-align: center;
  }

  .titulo {
    color: #fff;
    font-size: 2em;
    font-family: ChivoRegular, sans-serif;
  }
}

/* Small devices  horizontales (landscape phones, 576px and up) */

@media (max-width: 576px) {
  .colContainerTitle__title {
    font-size: 28px !important;
  }
}
@media (min-width: 576px) and (max-width: 680px) {
  .botonesAlineacion_Cancel {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding-top: 20px;
  }

  .col_cabecera {
    display: flex;
    justify-content: center;
  }

  .col_imgCancelacion {
    display: flex;
    justify-content: center;
  }

  .col_logo {
    padding-left: 2%;
  }

  .col_logoSalauno {
    display: flex;
    justify-content: center;
    padding-top: 3%;
  }

  .col_phone {
    display: flex;
    justify-content: center;
  }

  .col_txtCancelacion {
    display: flex;
    justify-content: center;
    padding-top: 4%;
  }

  .col_txtLlamanos {
    display: flex;
    justify-content: center;
    padding-top: 2%;
  }

  .phoneCancelacion {
    text-decoration: underline;
    font-family: DINNextRoundedLTPro, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: #10798b;
  }

  .responsableSanitarioCancelacion {
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
    color: #707070;
    font-family: ChivoRegular, sans-serif;
    padding-top: 2%;
    padding-bottom: 2.5%;
    padding-left: 16%;
    padding-right: 15%;
    text-align: center;
  }

  .row_btnSeccion {
    margin: 0;
  }

  .row_cabecera {
    background-color: #00778b;
    margin: 0;
  }

  .row_imgCancelacion {
    background-color: #00778b;
    margin: 0;
  }

  .row_txtLlamanos {
    margin: 0;
    display: none;
  }

  .row_Logo {
    background-color: #00778b;
    margin: 0;
  }

  .row_logoSalauno {
    margin: 0;
    display: none;
  }

  .row_phone {
    margin: 0;
    display: none;
  }

  .row_responsable {
    margin: 0;
  }

  .row_txtCancelacion {
    margin: 0;
  }

  .textCancelacion {
    text-align: -webkit-center;
    font-family: ChivoRegular, sans-serif;
    font-size: 1.3rem;
    color: #707070;
    margin-bottom: 0;
  }

  .titulo {
    color: #fff;
    font-size: 2em;
    font-family: ChivoRegular, sans-serif;
  }
}

@media (min-width: 681px) and (max-width: 767px) {
  .botonesAlineacion_Cancel {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding-top: 20px;
  }

  .col_cabecera {
    display: flex;
    justify-content: center;
  }

  .col_imgCancelacion {
    display: flex;
    justify-content: center;
  }

  .col_logo {
    padding-left: 2%;
  }

  .col_logoSalauno {
    display: flex;
    justify-content: center;
    padding-top: 3%;
  }

  .col_phone {
    display: flex;
    justify-content: center;
  }

  .col_txtCancelacion {
    display: flex;
    justify-content: center;
    padding-top: 4%;
  }

  .col_txtLlamanos {
    display: flex;
    justify-content: center;
    padding-top: 2%;
  }

  .phoneCancelacion {
    text-decoration: underline;
    font-family: DINNextRoundedLTPro, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: #10798b;
  }

  .responsableSanitarioCancelacion {
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
    color: #707070;
    font-family: ChivoRegular, sans-serif;
    padding-top: 2%;
    padding-bottom: 2.5%;
    padding-left: 19%;
    padding-right: 19%;
    text-align: center;
  }

  .row_btnSeccion {
    margin: 0;
  }

  .row_cabecera {
    background-color: #00778b;
    margin: 0;
  }

  .row_imgCancelacion {
    background-color: #00778b;
    margin: 0;
  }

  .row_txtLlamanos {
    margin: 0;
    display: none;
  }

  .row_Logo {
    background-color: #00778b;
    margin: 0;
  }

  .row_logoSalauno {
    margin: 0;
    display: none;
  }

  .row_phone {
    margin: 0;
    display: none;
  }

  .row_responsable {
    margin: 0;
  }

  .row_txtCancelacion {
    margin: 0;
  }

  .textCancelacion {
    text-align: -webkit-center;
    font-family: ChivoRegular, sans-serif;
    font-size: 1.3rem;
    color: #707070;
    margin-bottom: 0;
  }

  .titulo {
    color: #fff;
    font-size: 2em;
    font-family: ChivoRegular, sans-serif;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 767px) {
  .rowContainer {
    width: 75%;
  }
}
@media (min-width: 768px) {
  .botonesAlineacion_Cancel {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding-top: 7%;
  }

  .col_cabecera {
    display: flex;
    justify-content: center;
  }

  .col_imgCancelacion {
    display: flex;
    justify-content: center;
  }

  .col_logo {
    padding-left: 2%;
  }

  .col_logoSalauno {
    display: flex;
    justify-content: center;
    padding-top: 3%;
  }

  .col_phone {
    display: flex;
    justify-content: center;
  }

  .col_txtCancelacion {
    display: flex;
    justify-content: center;
    padding-top: 20%;
    padding-left: 26%;
    padding-right: 26%;
  }

  .col_txtLlamanos {
    display: flex;
    justify-content: center;
    padding-top: 2%;
  }

  .img_logoSalauno {
    width: 205.1px;
    height: 66.4px;
  }

  .phoneCancelacion {
    text-decoration: underline;
    font-family: DINNextRoundedLTPro, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: #10798b;
  }

  .responsableSanitarioCancelacion {
    display: flex;
    justify-content: center;
    font-size: 0.71rem;
    color: #707070;
    font-family: ChivoRegular, sans-serif;
    padding-top: 14%;
    padding-bottom: 2.5%;
    padding-left: 18%;
    padding-right: 15%;
    text-align: center;
  }

  .row_btnSeccion {
    margin: 0;
  }

  .row_cabecera {
    background-color: #00778b;
    margin: 0;
  }

  .row_imgCancelacion {
    background-color: #00778b;
    margin: 0;
  }

  .row_txtLlamanos {
    margin: 0;
    display: none;
  }

  .row_Logo {
    background-color: #00778b;
    margin: 0;
  }

  .row_logoSalauno {
    margin: 0;
  }

  .row_phone {
    margin: 0;
    display: none;
  }

  .row_responsable {
    margin: 0;
  }

  .row_txtCancelacion {
    margin: 0;
  }

  .textCancelacion {
    text-align: -webkit-center;
    font-family: ChivoRegular, sans-serif;
    font-size: 1.8rem;
    color: #707070;
    margin-bottom: 0;

    /* text-align: center; */
  }

  .titulo {
    color: #fff;
    font-size: 2em;
    font-family: ChivoRegular, sans-serif;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 991px) {
  .rowContainerButton {
    width: 100%;
  }
  .colContainerSubTitle__subtitle {
    font-size: 20px;
  }
  .colContainerTitle__title {
    font-size: 32px;
  }
}
@media (min-width: 992px) {
  .botonesAlineacion_Cancel {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding-top: 20px;
  }

  .col_cabecera {
    display: flex;
    justify-content: center;
  }

  .col_imgCancelacion {
    display: flex;
    justify-content: center;
  }

  .col_logo {
    padding-left: 2%;
  }

  .col_logoSalauno {
    display: flex;
    justify-content: center;
    padding-top: 3%;
  }

  .col_phone {
    display: flex;
    justify-content: center;
  }

  .col_txtCancelacion {
    display: flex;
    justify-content: center;
    padding-top: 4%;
  }

  .col_txtLlamanos {
    display: flex;
    justify-content: center;
    padding-top: 2%;
  }

  .phoneCancelacion {
    text-decoration: underline;
    font-family: DINNextRoundedLTPro, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: #10798b;
  }

  .responsableSanitarioCancelacion {
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
    color: #707070;
    font-family: ChivoRegular, sans-serif;
    padding-top: 2%;
    padding-bottom: 0.5%;
  }

  .row_btnSeccion {
    margin: 0;
  }

  .row_cabecera {
    background-color: #00778b;
    margin: 0;
  }

  .row_imgCancelacion {
    background-color: #00778b;
    margin: 0;
  }

  .row_txtLlamanos {
    margin: 0;
    display: none;
  }

  .row_Logo {
    background-color: #00778b;
    margin: 0;
  }

  .row_logoSalauno {
    margin: 0;
  }

  .row_phone {
    margin: 0;
    display: none;
  }

  .row_responsable {
    margin: 0;
  }

  .row_txtCancelacion {
    margin: 0;
    padding-top: 8%;
  }

  .textCancelacion {
    text-align: -webkit-center;
    font-family: ChivoRegular, sans-serif;
    font-size: 1.3rem;
    color: #707070;
    margin-bottom: 0;
  }

  .titulo {
    color: #fff;
    font-size: 2em;
    font-family: ChivoRegular, sans-serif;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .botonesAlineacion_Cancel {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding-top: 20px;
  }

  .col_cabecera {
    display: flex;
    justify-content: center;
  }

  .col_imgCancelacion {
    display: flex;
    justify-content: center;
  }

  .col_logo {
    padding-left: 2%;
  }

  .col_logoSalauno {
    display: flex;
    justify-content: center;
    padding-top: 3%;
  }

  .col_phone {
    display: flex;
    justify-content: center;
  }

  .col_txtCancelacion {
    display: flex;
    justify-content: center;
    padding-top: 4%;
  }

  .col_txtLlamanos {
    display: flex;
    justify-content: center;
    padding-top: 2%;
  }

  .phoneCancelacion {
    text-decoration: underline;
    font-family: DINNextRoundedLTPro, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: #10798b;
  }

  .responsableSanitarioCancelacion {
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
    color: #707070;
    font-family: ChivoRegular, sans-serif;
    padding-top: 2%;
    padding-bottom: 1.5%;
  }

  .row_btnSeccion {
    margin: 0;
  }

  .row_cabecera {
    background-color: #00778b;
    margin: 0;
  }

  .row_imgCancelacion {
    background-color: #00778b;
    margin: 0;
    padding-top: 2%;
  }

  .row_txtLlamanos {
    margin: 0;
  }

  .row_Logo {
    background-color: #00778b;
    margin: 0;
  }

  .row_logoSalauno {
    margin: 0;
  }

  .row_phone {
    margin: 0;
  }

  .row_responsable {
    margin: 0;
  }

  .row_txtCancelacion {
    margin: 0;
    padding-top: 2%;
  }

  .textCancelacion {
    text-align: -webkit-center;
    font-family: ChivoRegular, sans-serif;
    font-size: 1.3rem;
    color: #707070;
    margin-bottom: 0;
  }

  .titulo {
    color: #fff;
    font-size: 2em;
    font-family: ChivoRegular, sans-serif;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1500px) {
  .cancelarContainer {
    padding: 15% 0;
  }
  .botonesAlineacion_Cancel {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding-top: 20px;
  }

  .col_cabecera {
    display: flex;
    justify-content: center;
  }

  .col_imgCancelacion {
    display: flex;
    justify-content: center;
  }

  .col_logo {
    padding-left: 2%;
  }

  .col_logoSalauno {
    display: flex;
    justify-content: center;
    padding-top: 3%;
  }

  .col_phone {
    display: flex;
    justify-content: center;
  }

  .col_txtCancelacion {
    display: flex;
    justify-content: center;
    padding-top: 4%;
  }

  .col_txtLlamanos {
    display: flex;
    justify-content: center;
    padding-top: 2%;
  }

  .phoneCancelacion {
    text-decoration: underline;
    font-family: DINNextRoundedLTPro, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: #10798b;
  }

  .responsableSanitarioCancelacion {
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
    color: #707070;
    font-family: ChivoRegular, sans-serif;
    padding-top: 2%;
    padding-bottom: 0.5%;
  }

  .row_btnSeccion {
    margin: 0;
  }

  .row_cabecera {
    background-color: #00778b;
    margin: 0;
  }

  .row_imgCancelacion {
    background-color: #00778b;
    margin: 0;
  }

  .row_txtLlamanos {
    margin: 0;
  }

  .row_Logo {
    background-color: #00778b;
    margin: 0;
  }

  .row_logoSalauno {
    margin: 0;
  }

  .row_phone {
    margin: 0;
  }

  .row_responsable {
    margin: 0;
  }

  .row_txtCancelacion {
    margin: 0;
  }

  .textCancelacion {
    text-align: -webkit-center;
    font-family: ChivoRegular, sans-serif;
    font-size: 1.3rem;
    color: #707070;
    margin-bottom: 0;
  }

  .titulo {
    color: #fff;
    font-size: 2em;
    font-family: ChivoRegular, sans-serif;
  }
}
