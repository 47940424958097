.rowContainerImage__image {
  width: auto;
  max-width: 80%;
}
.rowContainerIncluye {
  color: #fff;
}
.rowContainerIncluye__title {
  font-family: DINNextRoundedLTPro, sans-serif;
  font-size: 1.7rem;
}
.rowContainerIncluye__ul {
  padding: 0;
  font-size: 1.2rem;
  width: 50%;
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
}
.rowContainerRestricciones__h3 {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  font-size: 1.5rem;
  color: #fff;
}
.rowContainerRestricciones__ul {
  list-style: none;
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  font-size: 1.15rem;
  color: #fff;
}
.footer {
  height: 13vmin;
  background-color: #00748c;
  width: 100%;
  margin: 0;
}

@media (max-width: 992px) {
  .rowContainerIncluye__title {
    font-size: 1.4rem;
  }
  .rowContainerIncluye__ul {
    font-size: 1rem;
    width: 70%;
  }
  .rowContainerRestricciones__ul {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .rowContainerIncluye__title {
    font-size: 1.1rem;
  }
  .rowContainerRestricciones__h3 {
    font-size: 1.3rem;
  }
  .rowContainerRestricciones__ul {
    font-size: 1rem;
  }
}

@media (max-width: 443px) {
  .rowContainerIncluye__title {
    font-size: 1rem;
  }
  .rowContainerIncluye__ul {
    width: 95%;
  }
  .rowContainerRestricciones__h3 {
    font-size: 1.1rem;
  }
  .rowContainerRestricciones__ul {
    font-size: 0.7rem;
  }
}
