.containerConfirmacion {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.rowDatosConfirmacion {
  /* background-color: red; */
  width: 100%;
  height: 90%;
  margin: 0;
  padding: 0;
}

.containerCita {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divContainerTitle {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divContainerDatos {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffce00;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.colDatosConfirmacion_Cita {
  /* background-color: green; */
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rowDatosCitas {
  /* background-color: red; */
  width: 95%;
}

.rowContainerItemColumns {
  margin: 0;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 90%;
  justify-content: center;
  /* background-color: blue; */
}

.colTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.colTitle p {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00788a;
  /* margin-bottom: 20px; */
}

.colContainerItem {
  /* background-color: red; */
  width: 100%;
}

.rowContainerItem {
}

.colItem_name {
  display: flex;
  justify-content: left;
  align-items: center;
  /* border: 1px solid black; */
}

.colItem_name p {
  margin: 0;
  font-size: 15px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00788a;
}

.colItem_value {
  display: flex;
  justify-content: left;
  align-items: center;
  /* border: 1px solid black; */
}

.colItem_value p {
  margin: 0;
  font-size: 15px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00788a;
  width: 100%;
  overflow-y: auto;
}

.colDatosConfirmacion_Cx {
  /* background-color: yellow; */
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.rowDatosCx {
  /* background-color: red; */
}

.rowButtons {
  /* background-color: blue; */
  width: 100%;
  height: 10%;
  margin: 0;
  padding: 0;
}

.colButtonCancelar {
  /* background-color: red; */
  justify-content: center;
  align-items: center;
  display: flex;
}

.buttonCancelar {
  width: 50%;
  height: 35px;
  border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background-color: #ffce00;
  font-size: 18px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00788a;
  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
}

.buttonCancelar:hover {
  color: #ffce00;
  background-color: #00788a;
}

.colButtonImprimir {
  /* background-color: green; */
  justify-content: center;
  align-items: center;
  display: flex;
}

.buttonImprimir {
  width: 50%;
  height: 35px;
  border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background-color: #ffce00;
  font-size: 18px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00788a;
  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
}

.buttonImprimir:hover {
  color: #ffce00;
  background-color: #00788a;
}

@media (max-width: 767px) {
  .colDatosConfirmacion_Cita {
    margin-top: 4%;
    height: 40%;
  }
  .colDatosConfirmacion_Cx {
    margin-top: 4%;
    height: 60%;
  }
  .divContainerDatos {
    margin-top: 1%;
  }
  .containerConfirmacion {
    height: 210%;
  }
  .rowButtons {
    height: 16%;
  }
}

@media print {
  .divContainerDatos {
    width: 90%;
    height: 100%;
  }

  .colDatosConfirmacion_Cita {
    flex: 0 0 100%;
    max-width: 100%;
    height: 50%;
  }

  .colDatosConfirmacion_Cx {
    flex: 0 0 100%;
    max-width: 100%;
    height: 60%;
  }

  .buttonCancelar {
    display: none;
  }

  .buttonImprimir {
    display: none;
  }
  .colItem_name p {
    font-size: 25px;
  }
  .colItem_value p {
    font-size: 25px;
  }
  .colTitle p {
    font-size: 25px;
  }
}
