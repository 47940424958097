.quien {
  font-size: 15px;
  color: #00606e;
  font-family: DINNextRoundedLTPro, sans-serif;
}

.dropdown {
  width: auto;
  height: 56px;
  border: none;
}

.checkbox {
  color: #10798b;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  text-decoration: underline;
}

.checkbox:hover {
  color: #ffcd00;
}

.toogleDropdown {
  width: 100%;
  height: 56px;
  border-radius: 12.5px;
  border: 2px solid #00778b;
  color: #667180 !important;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}
.toogleDropdown:hover {
  color: #667180 !important;
}

.datosEnteraste {
  width: 336px;
  height: 56px;
  border: 1px solid #53585f;
  border-radius: 50px;
  padding-left: 10%;
  padding-right: 7%;
  font-family: DINNextRoundedLTPro, sans-serif;
  font-weight: 100;
  font-size: 1.2rem;
  color: black;
}

.datosEnterasteActive {
  width: 336px;
  height: 56px;
  border: 1px solid #ffcd00;
  border-radius: 50px;
  padding-left: 10%;
  padding-right: 7%;
  font-family: DINNextRoundedLTPro, sans-serif;
  font-weight: 100;
  font-size: 1.2rem;
  color: black;
}

.datosJNH {
  width: 336px;
  height: 56px;
  border: 1px solid #53585f;
  border-radius: 50px;
  padding-left: 10%;
  padding-right: 7%;
  font-family: DINNextRoundedLTPro, sans-serif;
  font-weight: 100;
  font-size: 1.2rem;
  color: black;
}

.datosJNHActive {
  width: 336px;
  height: 56px;
  border: 1px solid #ffcd00;
  border-radius: 50px;
  padding-left: 10%;
  padding-right: 7%;
  font-family: DINNextRoundedLTPro, sans-serif;
  font-weight: 100;
  font-size: 1.2rem;
  color: black;
}

.turnoJNH {
  margin-left: -15rem;
}
.font_info_14 {
  font-size: 14px;
  color: #00778b;
  font-family: DINNextRoundedLTPro-Regurlar, sans-serif;
}

.CObligatorioContainer {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  color: #808080;
}

@media (min-width: 300px) and (max-width: 400px) {
  .col_apmPAC {
    padding-top: 7%;
  }

  .col_appPAC {
    padding-top: 7%;
  }

  .col_datoNum {
    padding-top: 5%;
  }

  .col_dejarDatos {
    padding-left: 8%;
    padding-right: 8%;
  }

  .col_Quien {
    position: relative;
  }

  .col_Tuyos {
    position: relative;
  }

  .col_txtAm {
    position: relative;
    padding-top: 5%;
  }

  .col_txtAp {
    position: relative;
    padding-top: 5%;
  }

  .container_form {
    padding-top: 13%;
  }

  .quien_form {
    font-size: 16px;
    color: #00778b;
    font-family: ChivoBold, sans-serif;
  }

  .row_Contactarte {
    padding-top: 14%;
  }

  .row_datos {
    padding-top: 7%;
    padding-left: 3%;
    padding-right: 3%;
    margin: 0;
  }

  .row_datoNum {
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 3%;
    margin: 0;
  }

  .row_DatosPaciente {
    position: relative;
    padding-top: 4%;
  }

  .row_dejarDatos {
    margin: 0;
  }

  .row_Donde {
    padding-top: 4%;
  }

  .row_LosDatosDe {
    padding-top: 11%;
  }

  .row_ParaQuien {
    padding-top: 9%;
  }

  .row_TusDatos {
    padding-top: 8%;
  }

  .datosEnteraste {
    width: 100%;
  }

  .datosEnterasteActive {
    width: 100%;
  }

  .turnoJNH {
    margin-left: 30%;
  }
}

@media (min-width: 401px) and (max-width: 575px) {
  .col_apmPAC {
    padding-top: 7%;
  }
  .col_appPAC {
    padding-top: 7%;
  }
  .col_datoNum {
    padding-top: 5%;
  }

  .col_dejarDatos {
    padding-left: 8%;
    padding-right: 8%;
  }

  .col_Quien {
    position: relative;
  }

  .col_Tuyos {
    position: relative;
  }

  .col_txtAm {
    position: relative;
    padding-top: 5%;
  }

  .col_txtAp {
    position: relative;
    padding-top: 5%;
  }

  .container_form {
    padding-top: 12%;
  }

  .quien_form {
    font-size: 16px;
    color: #00778b;
    font-family: ChivoBold, sans-serif;
  }

  .row_Contactarte {
    padding-top: 8%;
  }

  .row_datos {
    padding-top: 7%;
    padding-left: 3%;
    padding-right: 3%;
    margin: 0;
  }

  .row_datoNum {
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 3%;
    margin: 0;
  }

  .row_DatosPaciente {
    position: relative;
    padding-top: 4%;
  }

  .row_dejarDatos {
    margin: 0;
  }

  .row_Donde {
    padding-top: 4%;
  }

  .row_LosDatosDe {
    padding-top: 6%;
  }

  .row_ParaQuien {
    padding-top: 9%;
  }

  .row_TusDatos {
    padding-top: 6%;
  }

  .datosEnteraste {
    width: 100%;
  }

  .datosEnterasteActive {
    width: 100%;
  }

  .turnoJNH {
    margin-left: 30%;
  }
}

/* Small devices  horizontales (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 680px) {
  .col_apmPAC {
    display: flex;
    justify-content: center;
    padding-top: 7%;
  }
  .col_appPAC {
    display: flex;
    justify-content: center;
    padding-top: 7%;
  }

  .col_Cel {
    display: flex;
    justify-content: center;
  }

  .col_datoNum {
    display: flex;
    justify-content: center;
    padding-top: 5%;
  }

  .col_dejarDatos {
    padding-left: 3%;
    padding-right: 3%;
  }

  .col_DondeContac {
    display: flex;
    justify-content: center;
  }

  .col_NombreAC {
    display: flex;
    justify-content: center;
  }

  .col_nombrePAC {
    display: flex;
    justify-content: center;
    padding-top: 4%;
  }

  .col_Nombre {
    display: flex;
    justify-content: center;
  }

  .col_ParaQuien {
    display: flex;
    justify-content: center;
  }

  .col_Quien {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .col_Tuyos {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .col_txtAm {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 5%;
  }

  .col_txtAp {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 5%;
  }

  .container_form {
    padding-top: 8%;
  }

  .quien_form {
    font-size: 16px;
    color: #00778b;
    font-family: ChivoBold, sans-serif;
  }

  .row_Contactarte {
    padding-top: 8%;
  }

  .row_datos {
    padding-top: 3%;
    padding-left: 3%;
    padding-right: 3%;
    margin: 0;
  }

  .row_datoNum {
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 3%;
    margin: 0;
  }

  .row_DatosPaciente {
    position: relative;
    padding-top: 4%;
  }

  .row_dejarDatos {
    margin: 0;
  }

  .row_Donde {
    padding-top: 4%;
  }

  .row_LosDatosDe {
    padding-top: 6%;
  }

  .row_ParaQuien {
    padding-top: 5%;
  }

  .row_TusDatos {
    padding-top: 6%;
  }

  .datosEnteraste {
    width: 365px;
  }

  .datosEnterasteActive {
    width: 365px;
  }

  .turnoJNH {
    margin-left: 0;
  }
}

@media (min-width: 681px) and (max-width: 767px) {
  .col_apmPAC {
    display: flex;
    justify-content: center;
    padding-top: 7%;
  }

  .col_appPAC {
    display: flex;
    justify-content: center;
    padding-top: 7%;
  }

  .col_Cel {
    display: flex;
    justify-content: center;
  }

  .col_datoNum {
    display: flex;
    justify-content: center;
    padding-top: 5%;
  }

  .col_DondeContac {
    display: flex;
    justify-content: center;
  }

  .col_NombreAC {
    display: flex;
    justify-content: center;
  }

  .col_nombrePAC {
    display: flex;
    justify-content: center;
    padding-top: 4%;
  }

  .col_Nombre {
    display: flex;
    justify-content: center;
  }

  .col_ParaQuien {
    display: flex;
    justify-content: center;
  }

  .col_Quien {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .col_Tuyos {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .col_txtAm {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 5%;
  }

  .col_txtAp {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 5%;
  }

  .container_form {
    padding-top: 8%;
  }

  .quien_form {
    font-size: 18px;
    color: #00778b;
    font-family: ChivoBold, sans-serif;
  }

  .row_Contactarte {
    padding-top: 8%;
  }

  .row_datos {
    padding-top: 3%;
    padding-left: 3%;
    padding-right: 3%;
    margin: 0;
  }

  .row_datoNum {
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 3%;
    margin: 0;
  }

  .row_DatosPaciente {
    position: relative;
    padding-top: 4%;
  }

  .row_dejarDatos {
    margin: 0;
  }

  .row_Donde {
    padding-top: 4%;
  }

  .row_LosDatosDe {
    padding-top: 8%;
  }

  .row_ParaQuien {
    padding-top: 5%;
  }

  .row_TusDatos {
    padding-top: 10%;
  }

  .datosEnteraste {
    width: 365px;
  }

  .datosEnterasteActive {
    width: 365px;
  }

  .turnoJNH {
    margin-left: 0;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .col_Cel {
    display: flex;
    justify-content: flex-end;
  }

  .col_DondeContac {
    display: flex;
    justify-content: flex-start;
    padding-left: 0.1%;
  }

  .col_NombreAC {
    display: flex;
    justify-content: flex-end;
  }

  .col_Nombre {
    display: flex;
    justify-content: flex-end;
  }

  .col_ParaQuien {
    display: flex;
    justify-content: flex-start;
    padding-left: 1.3%;
  }

  .col_Quien {
    display: flex;
    justify-content: flex-start;
    padding-left: 0.1%;
  }

  .col_Tuyos {
    display: flex;
    justify-content: flex-start;
    padding-left: 0.1%;
  }

  .col_txtAm {
    display: flex;
    justify-content: flex-start;
  }

  .col_txtAp {
    display: flex;
    justify-content: center;
  }

  .container_form {
    padding-top: 6%;
  }

  .quien_form {
    font-size: 18px;
    color: #00778b;
    font-family: ChivoBold, sans-serif;
  }

  .row_Contactarte {
    padding-top: 8%;
    padding-left: 2%;
  }

  .row_datos {
    padding-top: 3%;
    margin: 0;
  }

  .row_datoNum {
    padding-top: 2%;
    margin: 0;
  }

  .row_DatosPaciente {
    position: relative;
    padding-top: 4%;
  }

  .row_dejarDatos {
    margin: 0;
  }

  .row_Donde {
    padding-top: 4%;
  }

  .row_LosDatosDe {
    padding-top: 8%;
    padding-left: 2%;
  }

  .row_ParaQuien {
    padding-top: 8%;
  }

  .row_TusDatos {
    padding-top: 10%;
    padding-left: 2%;
  }

  .datosEnteraste {
    width: 230px;
  }

  .datosEnterasteActive {
    width: 230px;
  }

  .turnoJNH {
    margin-left: 3rem;
  }
  .CObligatorioContainer {
    display: flex;
    justify-content: flex-start;
    padding-left: 2rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .col_Cel {
    display: flex;
    justify-content: flex-end;
  }

  .col_DondeContac {
    display: flex;
    justify-content: flex-start;
    padding-left: 5%;
  }

  .col_NombreAC {
    display: flex;
    justify-content: flex-end;
  }

  .col_Nombre {
    display: flex;
    justify-content: flex-end;
  }

  .col_ParaQuien {
    display: flex;
    justify-content: flex-start;
    padding-left: 4%;
  }

  .col_Quien {
    display: flex;
    justify-content: flex-start;
    padding-left: 5%;
  }

  .col_Tuyos {
    display: flex;
    justify-content: flex-start;
    padding-left: 5%;
  }

  .col_txtAm {
    display: flex;
    justify-content: flex-start;
  }

  .col_txtAp {
    display: flex;
    justify-content: center;
  }

  .container_form {
    padding-top: 6%;
  }

  .row_Contactarte {
    padding-top: 8%;
    padding-left: 0;
  }

  .row_datos {
    padding-top: 3%;
    margin: 0;
  }

  .row_datoNum {
    padding-top: 2%;
    margin: 0;
  }

  .row_DatosPaciente {
    position: relative;
    padding-top: 4%;
  }

  .row_dejarDatos {
    margin: 0;
  }

  .row_Donde {
    padding-top: 4%;
  }

  .row_LosDatosDe {
    padding-top: 8%;
    padding-left: 0;
  }

  .row_ParaQuien {
    padding-top: 10%;
  }

  .row_TusDatos {
    padding-top: 10%;
    padding-left: 0;
  }

  .datosEnteraste {
    width: 275px;
  }

  .turnoJNH {
    margin-left: 3rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .container_form {
    padding-top: 6%;
  }

  .col_Cel {
    display: flex;
    justify-content: flex-end;
  }

  .col_DondeContac {
    display: flex;
    justify-content: flex-start;
    padding-left: 7.3%;
  }

  .col_NombreAC {
    display: flex;
    justify-content: flex-end;
  }

  .col_Nombre {
    display: flex;
    justify-content: flex-end;
  }

  .col_ParaQuien {
    display: flex;
    justify-content: flex-start;
    padding-left: 7.3%;
  }

  .col_Quien {
    display: flex;
    justify-content: flex-start;
    padding-left: 7.3%;
  }

  .col_Tuyos {
    display: flex;
    justify-content: flex-start;
    padding-left: 7.3%;
  }

  .col_txtAm {
    display: flex;
    justify-content: flex-start;
  }

  .col_txtAp {
    display: flex;
    justify-content: center;
  }

  .row_Contactarte {
    padding-top: 2%;
    padding-left: 0;
  }

  .row_datos {
    padding-top: 3%;
  }

  .row_datoNum {
    padding-top: 2%;
  }

  .row_DatosPaciente {
    position: relative;
    padding-top: 1%;
  }

  .row_Donde {
    padding-top: 1%;
  }

  .row_LosDatosDe {
    padding-top: 2%;
  }

  .row_ParaQuien {
    padding-top: 6%;
  }

  .row_TusDatos {
    padding-top: 6%;
  }

  .datosEnteraste {
    width: 336px;
  }

  .datosEnterasteActive {
    width: 336px;
  }

  .turnoJNH {
    margin-left: -15rem;
  }
}

/* Extra large devices (large desktops, 1500px and up) */
@media (min-width: 1500px) {
  .col_Cel {
    display: flex;
    justify-content: flex-end;
  }

  .col_DondeContac {
    display: flex;
    justify-content: flex-start;
    padding-left: 15%;
  }

  .col_NombreAC {
    display: flex;
    justify-content: flex-end;
  }

  .col_Nombre {
    display: flex;
    justify-content: flex-end;
  }

  .col_ParaQuien {
    display: flex;
    justify-content: flex-start;
    padding-left: 14.9%;
  }

  .col_Quien {
    display: flex;
    justify-content: flex-start;
    padding-left: 15%;
  }

  .col_Tuyos {
    display: flex;
    justify-content: flex-start;
    padding-left: 15%;
  }

  .col_txtAm {
    display: flex;
    justify-content: flex-start;
  }

  .col_txtAp {
    display: flex;
    justify-content: center;
  }

  .container_form {
    padding-top: 6%;
  }

  .row_Contactarte {
    padding-top: 2.5%;
  }

  .row_datos {
    padding-top: 3%;
  }

  .row_datoNum {
    padding-top: 2%;
  }

  .row_DatosPaciente {
    position: relative;
    padding-top: 1%;
  }

  .row_Donde {
    padding-top: 1%;
  }

  .row_LosDatosDe {
    padding-top: 2.5%;
  }

  .row_ParaQuien {
    padding-top: 4%;
  }

  .row_TusDatos {
    padding-top: 4%;
  }
}
