.containerGeneralFooter {
  width: 100%;
  height: 103px;
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.06);
  position: absolute;
  /* bottom: 0;
  left: 0; */
}
.rowGeneralFooter {
  width: 100%;
  height: 100%;
  /* background-color: blue; */
  margin: 0;
}
.colContainerTitle {
  height: 100%;
}
.colContainerTitle__title {
  margin: auto 0;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00606e;
  letter-spacing: 4px;
  font-size: 14px;
}
.colContainerIcons__icons {
  margin: 0;
  padding: 0;
  padding-right: 10px;
}
.colContainerIcons__svg {
  width: 15px;
  height: 15px;
  margin: 0 2.5px;
}
@media (max-width: 443px) {
  .containerGeneralFooter {
    height: 70px;
  }
  .colContainerIcons {
    display: none !important;
  }
  .colContainerTitle {
    margin: 0 auto;
    padding: 0;
  }
  .colContainerTitle__title {
    font-size: 7px;
    text-align: center;
    letter-spacing: 2px;
  }
  .colContainerTitle__title {
    font-size: 7px !important;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .colContainerTitle__title {
    font-size: 12px;
    text-align: center;
  }
}
