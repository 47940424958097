.avisoContainer {
  width: auto;
  height: auto;
  background-color: #ffcd00;
  border-radius: 50px;
  margin: 0 auto;
  padding: 1rem;
}
.avisoContainer__mascot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.avisoContainer__svg {
  width: 80%;
  height: 80%;
  width: 30vmin;
  height: 30vmin;
}
.avisoContainer__aviso {
  display: flex;
  align-items: center;
  height: auto;
  margin: auto 0;
  padding-top: 20px;
  color: #10798b;
  font-size: 3vmin;
  font-weight: bold;
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
}
