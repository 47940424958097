@font-face {
  font-family: "DINNextRoundedLTPro";
  font-style: bold;
  font-weight: 400;
  src: url("../fonts/DINNextRoundedLTPro-Bold.eot");
  src: url("../fonts/DINNextRoundedLTPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/DINNextRoundedLTPro-Bold.eot") format("woff2"),
    url("../fonts/DINNextRoundedLTPro-Bold.ttf") format("truetype"),
    url("../fonts/DINNextRoundedLTPro-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "DINNextRoundedLTPro-Medium";
  font-style: medium;
  font-weight: 200;
  src: url("../fonts/DINNextRoundedLTPro-Medium.eot");
  src: url("../fonts/DINNextRoundedLTPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/DINNextRoundedLTPro-Medium.eot") format("woff2"),
    url("../fonts/DINNextRoundedLTPro-Medium.ttf") format("truetype"),
    url("../fonts/DINNextRoundedLTPro-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "DINNextRoundedLTPro-Regular";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/DINNextRoundedLTPro-Regular.eot");
  src: url("../fonts/DINNextRoundedLTPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/DINNextRoundedLTPro-Regular.eot") format("woff2"),
    url("../fonts/DINNextRoundedLTPro-Regular.ttf") format("truetype"),
    url("../fonts/DINNextRoundedLTPro-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "ChivoBold";
  font-style: bold;
  font-weight: 400;
  src: url("../fonts/Chivo-Bold.ttf");
}

@font-face {
  font-family: "ChivoRegular";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/Chivo-Regular.ttf");
}

@font-face {
  font-family: FontAwesome;
  src: url("// netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.eot?v=4.6.3");
  src: url("// netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.eot?#iefix&v=4.6.3")
      format("embedded-opentype"),
    url("// netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.woff2?v=4.6.3")
      format("woff2"),
    url("// netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.woff?v=4.6.3")
      format("woff"),
    url("// netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.ttf?v=4.6.3")
      format("truetype"),
    url("// netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
