.container {
  width: 100%;
  height: 100%;
  padding: 0;
}

.boxTxt {
  width: 100%;
  border: none;
  height: 30%;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00606e;
  font-size: 1em;
  position: absolute;
  top: -35px;
  left: 0px;
}
.TextInputGeneral,
.TextInputGeneral:focus {
  border: none;
  width: 100%;
  color: #667180 !important;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  background-color: transparent;
  outline: none;
  text-align: center;
  height: auto;
}
.TextInput,
.TextInput:focus {
  border: none;
  width: 100%;
  color: #667180 !important;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  background-color: transparent;
  outline: none;
  text-align: left;
  height: auto;
}
.TextInputGeneral::placeholder {
  text-align: center;
  color: #667180;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
}
.TextInput::placeholder {
  text-align: left;
  color: #667180;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
}
.TextInput::-webkit-calendar-picker-indicator{
  display: none;
  -webkit-appearance: none;
}

.inputLabel {
  width: 255px;
  max-width: 255px;
  height: 56px;
  border: 2px solid #00788a;
  border-radius: 12.5px;
  padding-left: 10%;
  padding-right: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.inputLabelDatosPaciente {
  width: 100%;
  height: 56px;
  border: 2px solid #00748c;
  border-radius: 12.5px;
  padding-left: 10%;
  padding-right: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.inputLabel2 {
  position: relative;
  width: auto;
  height: 56px;
  border: 2px solid #00748c;
  border-radius: 12.5px;
  padding-left: 10%;
  padding-right: 7%;
  color: #a9a9a9;
}

.TextInput2 {
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
  color: #667180;
  font-size: 20px;
  background-color: transparent;
  /* font-family: ChivoRegular, sans-serif; */
  font-family: DINNextRoundedLTPro, sans-serif;
  text-align: center;
}

::placeholder {
  color: #000;
  font-size: 1.2em;
  font-family: ChivoRegular, sans-serif;
}

.errorLabel {
  width: 100%;
  border: none;
  padding-left: 10%;
  color: #f00;
  font-family: ChivoRegular, sans-serif;
  font-size: 1em;
}

.dateInput {
  padding-left: 3%;
  padding-right: 3%;
}

.marginCorrection {
  margin-top: -2px !important;
}

/* Extra- Small devices verticales (landscape phones, 576px and up) */
@media (max-width: 443px) {
  .inputLabel {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .boxTxt {
    width: 100%;
    border: none;
    height: 30%;
    font-size: 1em;
  }

  .errorLabel {
    width: 100%;
    border: none;
    padding-left: 10%;
    color: #f00;
    font-family: ChivoRegular, sans-serif;
    font-size: 1em;
  }

  .inputLabel2 {
    width: 100%;
  }

  ::placeholder {
    color: #000;
    font-size: 1.2em;
    font-family: ChivoRegular, sans-serif;
  }

  .TextInput2 {
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    color: #667180;
    font-size: 20px;
  }
}

@media (min-width: 401px) and (max-width: 575px) {
  .boxTxt {
    width: 100%;
    border: none;
    height: 30%;
    font-size: 1em;
  }

  .errorLabel {
    width: 100%;
    border: none;
    padding-left: 10%;
    color: #f00;
    font-family: ChivoRegular, sans-serif;
    font-size: 1em;
  }

  .inputLabel2 {
    width: 100%;
  }

  ::placeholder {
    color: #000;
    font-size: 1.2em;
    font-family: ChivoRegular, sans-serif;
  }

  .TextInput2 {
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    color: #667180;
    font-size: 20px;
  }
}

/* Small devices  horizontales (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 680px) {
  .boxTxt {
    width: 100%;
    border: none;
    height: 30%;
    font-size: 1em;
  }

  .errorLabel {
    width: 100%;
    border: none;
    padding-left: 10%;
    color: #f00;
    font-family: ChivoRegular, sans-serif;
    font-size: 1em;
  }

  ::placeholder {
    color: #000;
    font-size: 1.2em;
    font-family: ChivoRegular, sans-serif;
  }

  .TextInput2 {
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    color: #667180;
    font-size: 20px;
  }
}

@media (min-width: 681px) and (max-width: 767px) {
  .boxTxt {
    width: 100%;
    border: none;
    height: 30%;
    font-size: 1em;
  }

  .errorLabel {
    width: 100%;
    border: none;
    padding-left: 10%;
    color: #f00;
    font-family: ChivoRegular, sans-serif;
    font-size: 1em;
  }

  ::placeholder {
    color: #000;
    font-size: 1.2em;
    font-family: ChivoRegular, sans-serif;
  }

  .TextInput2 {
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    color: #667180;
    font-size: 20px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .container {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .boxTxt {
    width: 100%;
    border: none;
    height: 30%;
    font-size: 1em;
  }
  .TextInput2 {
    /* position: relative;
    top: -3px; */
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    color: #667180;
    font-size: 18px;
  }

  ::placeholder {
    color: #000;
    font-size: 1.2em;
    font-family: ChivoRegular, sans-serif;
  }

  .errorLabel {
    width: 100%;
    border: none;
    padding-left: 10%;
    color: #f00;
    font-family: ChivoRegular, sans-serif;
    font-size: 1em;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .container {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .boxTxt {
    width: 100%;
    border: none;
    height: 30%;
    font-size: 1em;
  }

  .TextInput2 {
    /* position: relative;
    top: -3px; */
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    color: #667180;
    font-size: 18px;
  }

  ::placeholder {
    color: #000;
    font-size: 1.2em;
    font-family: ChivoRegular, sans-serif;
  }

  .errorLabel {
    width: 100%;
    border: none;
    padding-left: 10%;
    color: #f00;
    font-family: ChivoRegular, sans-serif;
    font-size: 1em;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .TextInput2 {
    /* position: relative;
    top: -3px; */
    height: 100%;
  }
}

/* Extra super large devices (large desktops, 1500px and up) */
@media (min-width: 1500px) {
  .TextInput2 {
    /* position: relative;
    top: -3px; */
    height: 100%;
  }

  .errorLabel {
    width: 100%;
    border: none;
    padding-left: 10%;
    color: #f00;
    font-family: ChivoRegular, sans-serif;
    font-size: 1em;
  }

  .boxTxt {
    width: 100%;
    border: none;
    height: 30%;
    font-size: 1em;
  }
}
