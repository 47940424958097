.containerPulseLoader {
  height: 100%;
}

.pulseLoaderContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rowContainerPulseLoader {
  /* height: 100%; */
}

.colContainerPulseLoader {
  /* height: 100%; */
}

.coment {
  margin-top: 1rem;
  font-size: 18px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00788a;
}
