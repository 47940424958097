.container {
  width: 100%;
  height: 100vh;
  background-color: #10798b;
}

.conInfo__p {
  color: #ffffff;
  font-family: DINNextRoundedLTPro, sans-serif;
  font-size: 25px;
  text-align: center;
}
