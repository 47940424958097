html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.containerBienvenido {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  min-height: 300px;
}
.bienvenidoContainer {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0;
}
.rowContainerFormulario {
  width: 100%;
  height: 70%;
  margin: 0;
  padding: 0;
}
.colContainerFormulario {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.formContainer {
  /* background-color: green; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.formContainer__h1 {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
  color: #00606e;
}
.containerInFormulario {
  /* background-color: orange; */
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
}
.rowContainerItems {
  /* background-color: red; */
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  width: 60%;
  height: 100%;
}
.colContainerItems {
  /* background-color: blue; */
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rowContainerItem {
  /* background-color: yellow; */
  width: 100%;
}
.colContainerLabel {
  /* background-color: yellowgreen; */
  display: flex;
  justify-content: center;
}
.colContainerLabel__label {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
  color: #00606e;
  display: flex;
}
.colContainerInput {
  /* background-color: purple; */
  display: flex;
  justify-content: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: none;
}

.colContainerInput__input {
  width: 255px;
  max-width: 255px;
  height: 40px;
  border: 2px solid #00788a;
  color: #667180 !important;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
  border-radius: 12.5px;
  padding-left: 10%;
  padding-right: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none;
}

.colContainerInput__input::placeholder {
  color: #667180 !important;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
}

.rowContainerItem__select {
  width: 255px;
  max-width: 255px;
  height: 40px;
  border: 2px solid #00788a;
  color: #667180 !important;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
  border-radius: 12.5px;
  padding-left: 10%;
  padding-right: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none;
}

.rowContainerItem__textArea {
  width: 255px;
  max-width: 255px;
  height: 65px;
  border: 2px solid #00788a;
  color: #667180 !important;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
  border-radius: 12.5px;
  padding-left: 10%;
  padding-right: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none;
  resize: none;
  -webkit-border-radius: 12.5px;
  -moz-border-radius: 12.5px;
  -ms-border-radius: 12.5px;
  -o-border-radius: 12.5px;
}

.rowContainerItem__textArea::placeholder {
  color: #667180;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
}

.colContainerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.colContainerButton__button,
.colContainerButton__button:focus {
  height: 50px;
  width: 100%;
  max-width: 244px;
  background-color: #ffd83d;
  color: #00788a;
  border: none;
  border-radius: 12.5px;
  cursor: pointer;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  outline: none;
}

.colContainerButton__button span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffcd00;
}
.colContainerButton__button span svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.colContainerSpanBoton {
  padding: 0;
  padding-left: 15%;
}

@media(min-width:1200px){
  .rowContainerFormulario{
    height: 100%;
  }
}

@media(max-height:550px){ 
.containerBienvenido {
  height: 100vh;
}
}

@media(max-height:657px){ 
  /* 645px */
  .containerBienvenido {
    min-height:inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bienvenidoContainer{
    /* height: auto; */
    height:fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767px) {
.containerBienvenido {
    height: 150vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .formContainer__h1 {
    font-size: 14px;
    margin-top: 15px;
  }
  .rowContainerItems {
    width: 90%;
  }
  .containerInFormulario {
    height: 100%;
  }
  .colContainerLabel {
    display: flex;
    justify-content: center;
  }
  .colContainerInput {
    display: flex;
    justify-content: center;
  }
  .colContainerButton {
    justify-content: center;
    margin-top: 5px;
  }
}

@media (max-width: 550px) {
  .colContainerInput__input {
    /* width: 150px; */
  }
}

@media (max-width: 442px) {
  .colContainerTitle__title {
    font-size: 32px;
  }
  .colContainerButton {
    padding-top: 30px;
  }
}
