/* //! Toast para formas de pago */
.toastFormaDePagoContainer {
  position: absolute;
  width: 100%;
  height: 85%;
  left: -8%;
  z-index: 10;
  /* background-color: #ffcd00; */
  background-color: #fff;
  padding: 10px 0;
  transition: all 0.5s;
}
.disableToastContainer {
  display: none;
}
.toastFormaDePago {
  height: 100%;
  margin: 0 auto;
  /* background-color: #ffcd00; */
}
.toastFormaDePago__toastHeader {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  color: #10798b;
  font-size: 16px;
}
.backgroundToast__Yellow {
  background-color: #ffcd00;
}
.backgroundToast__Blue {
  background-color: #10798b;
}
.toastFormaDePago__toastBody {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  color: #10798b;
  font-size: 14px;
}

/* //! Toast General */

@media (max-width: 991px) {
  /* //! Medias para toast formas de pago */
  .toastContainer {
    width: 100%;
    height: 100%;
    top: 0;
    left: 20px;
    padding: auto 0;
  }
  .toast {
    height: 50%;
  }
  /* //!Medias para toast General */
}
@media (max-width: 767px) {
  /* //! Medias para toast formas de pago */
  .toastContainer {
    width: 100%;
    height: 100%;
    top: 0;
    left: 20px;
  }
  /* //!Medias para toast General */
}
