.imgModalNoLead {
  display: flex;
  justify-content: center;
  padding-top: 5%;
}

.textModalNoLead {
  text-align: center;
  font-family: ChivoRegular, sans-serif;
  font-size: 1.4rem;
  padding-top: 5%;
}

.rowBotonNoLead {
  justify-content: center;
  padding-top: 5%;
}

.rowNoLead {
  display: none;
}

.notaLasik {
  color: #00748c;
}

/* Extra- Small devices verticales (landscape phones, 576px and up) */

@media (min-width: 300px) and (max-width: 400px) {
  .col_alerta {
    padding-left: 8%;
    padding-right: 8%;
  }

  .row_pregunta {
    padding-top: 2%;
    margin: 0;
  }

  .row_alerta {
    padding-top: 2%;
    margin: 0;
  }
}

@media (min-width: 401px) and (max-width: 575px) {
  .col_alerta {
    padding-left: 8%;
    padding-right: 8%;
  }

  .row_pregunta {
    padding-top: 2%;
    margin: 0;
  }

  .row_alerta {
    padding-top: 2%;
    margin: 0;
  }
}

/* Small devices  horizontales (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 680px) {
  .col_alerta {
    padding-left: 8%;
    padding-right: 8%;
  }

  .row_pregunta {
    padding-top: 2%;
    margin: 0;
  }

  .row_alerta {
    padding-top: 2%;
    margin: 0;
  }
}

@media (min-width: 681px) and (max-width: 767px) {
  .col_alerta {
    padding-left: 8%;
    padding-right: 8%;
  }

  .row_pregunta {
    padding-top: 2%;
    margin: 0;
  }

  .row_alerta {
    padding-top: 2%;
    margin: 0;
  }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .col_alerta {
    padding-left: 8%;
    padding-right: 8%;
  }

  .row_pregunta {
    padding-top: 2%;
    margin: 0;
  }

  .row_alerta {
    padding-top: 2%;
    margin: 0;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .col_alerta {
    padding-left: 8%;
    padding-right: 8%;
  }

  .row_pregunta {
    padding-top: 2%;
    margin: 0;
  }

  .row_alerta {
    padding-top: 2%;
    margin: 0;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .col_alerta {
    padding-left: 7%;
    padding-right: 7%;
  }

  .row_pregunta {
    padding-top: 2%;
    margin: 0;
  }

  .row_alerta {
    padding-top: 2%;
    margin: 0;
  }
}

/* Extra large super devices (large desktops, 1500px and up) */

@media (min-width: 1500px) {
  .col_alerta {
    padding-left: 7%;
    padding-right: 7%;
  }

  .row_pregunta {
    padding-top: 2%;
    margin: 0;
  }

  .row_alerta {
    padding-top: 2%;
    margin: 0;
  }
}
