.container {
  width: 100%;
  height: auto;
  padding: 0;
  background-color: rgba(102, 113, 128, 0.06);
}
.containerLlamadaVideoLlamada {
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  flex-direction: column;
}
.rowContainerLlamadaVideoLlamada {
  width: 75%;
  border-radius: 20px;
  border: 4px solid #fff;
  padding: 50px 0;
}
.colContainerTitle {
  padding-bottom: 30px;
}
.colContainerTitle__title {
  display: inline-block;
  /* width: 60%; */
  height: 40px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
  color: #00748c;
  text-align: center;
  max-width: 566px;
}
.rowContainerButtons {
  padding: 40px 0;
  padding-top: 80px;
  width: 30%;
}
.colContainerButton__button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  width: 100%;
  max-width: 244px;
  background-color: #fdcd2f;
  color: #00748c;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
  border: none;
  border-radius: 10px;
  outline: none;
}
.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.colContainerSpanBoton {
  padding: 0;
}

.colContainerButton__button:hover {
  background-color: #00748c;
  color: #fff;
}
.colContainerButton__button:hover span {
  background-color: transparent;
}
.colContainerButton__button:hover .colContainerButton__svg {
  fill: #fff;
}
.colContainerButton__span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fac61b;
}
.colContainerButton__svg {
  width: 15px;
  height: 15px;
  fill: #fff;
}
@media (min-width: 1500px) {
  .container {
    padding-top: 50px;
    height: 55vh;
  }
}
@media (min-height: 1200px) {
  .container {
    height: calc(100% - 275px);
  }
}
@media (max-width: 991px) {
  .colContainerTitle__title {
    width: 80%;
    height: 50px;
  }
  .rowContainerButtons {
    width: 50%;
  }
}
@media (max-width: 992px) {
  .container {
    /* height: 100%; */
    padding-top: 50px;
  }
}
@media (max-width: 768px) {
  .container {
    height: auto;
    padding-top: 50px;
  }
}
@media (max-width: 767px) {
  .colContainerTitle__title {
    width: 100%;
    font-size: 15px;
  }
  .containerLlamadaVideoLlamada {
    padding-top: 60px;
  }
}

@media (max-width: 446px) {
  .colContainerLlamadaVideoLlamada {
    margin-top: 20px;
  }
  .rowContainerButtons {
    width: 90%;
  }
  .colContainerButton__button {
    width: 70%;
  }
  .rowContainerLlamadaVideoLlamada {
    width: 85% !important;
  }
  .containerLlamadaVideoLlamada {
    padding-top: 40px !important;
  }
}

@media (max-width: 390px) {
  .colContainerButton__span {
    margin: auto 0;
    margin-left: 35px;
  }
  .container {
    padding-top: 0;
  }
}
