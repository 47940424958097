html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.containerBienvenido {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  min-height: 300px;
}

.containerBienvenidoCirugia {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  min-height: 300px;
}

.bienvenidoContainer {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0;
}
.rowContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* background-color: red; */
}

.colContainer {
  /* background-color: blue; */
}

.rowContainerTitle {
  /* background-color: green; */
  color: #01778b;
  font-weight: bold;
  /* margin-top: 0.5em; */
  height: 10%;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}

.rowContainerButtons {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 70px;
  border: 2px solid #ffce00;
  /* margin-top: 0.5em; */
  border-radius: 12px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
}

.colButton {
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.colButton_button {
  height: 50px;
  min-width: 100px;
  width: auto;
  color: #01778b;
  font-weight: 600;
  cursor: pointer;
  -webkit-user-select: none;
  background-color: transparent;
  border: 2px solid #ffce00;
  font-size: 12px;
  border-radius: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.colButton_button__hover {
  height: 50px;
  min-width: 100px;
  width: auto;
  font-weight: 600;
  color: #01778b;
  cursor: pointer;
  -webkit-user-select: none;
  background-color: transparent;
  border: 2px solid #ffce00;
  font-size: 12px;
  border-radius: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  color: white;
  background-color: #01778b;
}

.colButton_button:hover {
  color: white;
  background-color: #01778b;
  border: none;
}

.colButton_button:focus {
  color: white;
  background-color: #01778b;
  border: 2px solid #ffce00;
}

.rowCategorias {
  /*background-color: red;
  */
  width: 90%;
  max-height: 65%;
  min-height: 35%;
  height: auto;
  /* max-height: 55%;
  min-height: 55%; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  border: 2px solid #ffce00;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 70px;
}

.rowCategoriasCIrugia {
  width: 90%;
  max-height: 65%;
  height: 65%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  border: 2px solid #ffce00;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding-top: 10px;
  padding-bottom: 10px;
}

.colCategoria {
  display: flex;
  /* background-color: blue; */
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  padding-top: 5px;
  height: 5%;
}

.colCategoria_Button {
  width: 90%;
  font-weight: 600;
  cursor: pointer;
  -webkit-user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 12px;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #01778b;
  font-weight: 600;
  border: 2px solid #ffce00;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  -ms-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.colCategoria_Button:focus {
  color: white;
  background-color: #01778b;
  border: 2px solid #ffce00;
}

.colCategoria_Button:hover {
  color: white;
  background-color: #01778b;
  /* border: none; */
}

.colCategoria_Button_hover {
  width: 90%;
  cursor: pointer;
  -webkit-user-select: none;
  border: 1px solid transparent;
  font-size: 12px;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 600;
  border: 2px solid #ffce00;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: white;
  background-color: #01778b;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  -ms-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.rowButtonContinuar {
  /* background-color: red; */
  height: 2.5rem;
}

.colButtonContinuar {
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.colContainerButton__button,
.colContainerButton__button:focus {
  height: 50px;
  width: 100%;
  max-width: 244px;
  background-color: #ffd83d;
  color: #00788a;
  border: none;
  border-radius: 12.5px;
  cursor: pointer;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  outline: none;
}

.colContainerButton__button span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffcd00;
}
.colContainerButton__button span svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.colContainerSpanBoton {
  padding: 0;
  padding-left: 15%;
}

@media (max-width: 767px) {
  .containerBienvenidoCirugia {
    height: 170%;
  }
}
