/* //!Comienzan estilos para Horarios */

.containerFechaCitaFluid {
  background-color: rgba(102, 113, 128, 0.06);
  padding-top: 0;
  padding-top: 70px;
  padding-bottom: 40px;
}
.containerFechaCita {
  width: 70%;
  border-radius: 20px;
  border: 3px solid #fff;
  padding-top: 20px;
}
.rowNotAvailable {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.notAvailable {
  width: 70%;
  height: 70%;
  align-self: center;
}
.sweetLoading {
  text-align: -webkit-center;
  text-align: center;
}
.colContainerTitleHorarios {
  padding-top: 40px;
}
.colContainerTitleHorarios__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
  color: #00606e;
}
.fechaHeaderLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3%;
  font-size: 20px;
  font-family: DINNextRoundedLTPro, sans-serif;
  padding-top: 0.25rem;
}
.row_fecha {
  margin: 0;
}
.fechaArea {
  padding-top: 20px;
  padding-right: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.fechaElement {
  width: 100%;
  font-size: 18px;
  color: #00606e;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  margin: 0;
}
.fechaArea__p {
  padding-bottom: 20px;
  text-align: center;
}
.rowContainerButtons {
  width: 100%;
}
.colContainerButtons {
  padding: 0;
  padding-top: 50px;
  margin: 0;
  width: 100%;
}
.rowButtons {
  width: 100%;
}
.colContainerRegresar {
  padding: 10px;
}
button:focus {
  outline: none;
}
.colContainerButtons__regresar,
.colContainerButtons__regresar:focus {
  width: 100%;
  max-width: 244px;
  height: 50px;
  background-color: rgba(102, 113, 128, 0.06);
  border-radius: 12.5px;
  outline: none !important;
  border: 1px solid #00788a;
  padding: 10px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
  color: #00788a;
}
.colContainerContinuar {
  padding: 10px;
}
.colContainerButtons__continuar,
.colContainerButtons__continuar:focus {
  width: 100%;
  max-width: 244px;
  height: 50px;
  background-color: #fdcd2f;
  color: #00748c;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 14px;
  border: none;
  border-radius: 10px;
  outline: none;
}
.colContainerButtons__continuar:hover {
  background-color: #00748c;
  color: #fff;
}
.colContainerButtons__continuar:hover span {
  background-color: transparent;
}
.colContainerButtons__continuar:hover .colContainerButtons__svg {
  fill: #fff;
}
.colContainerButtons__continuar:disabled {
  cursor: pointer;
  background-color: #dddee0;
  color: #2a4857;
}
.colContainerButtons__continuar:disabled:hover {
  color: #2a4857;
}
.colContainerButtons__continuar:disabled .colContainerButtons__span {
  background-color: #dddee0;
}
.colContainerButtons__continuar:disabled .colContainerButtons__svg {
  fill: #2a4857;
}
.colContainerButtons__continuar:disabled:hover .colContainerButtons__svg {
  fill: #2a4857;
}
.colContainerNombreBoton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.colContainerSpanBoton {
  padding: 0;
}
.colContainerButtons__span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fac61b;
}
.colContainerButtons__svg {
  width: 15px;
  height: 15px;
  fill: #fff;
}
@media (min-width: 300px) and (max-width: 400px) {
  .rowNotAvailable {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .notAvailable {
    width: 95%;
    height: 95%;
    align-self: center;
  }
}
@media (min-width: 401px) and (max-width: 575px) {
  .rowNotAvailable {
    align-items: center;
    justify-content: center;
    width: 107%;
    height: 100%;
  }
  .notAvailable {
    width: 95%;
    height: 95%;
    align-self: center;
  }
}
@media (max-width: 443px) {
  .containerFechaCitaFluid {
    /* height: auto !important; */
    padding-top: 46px !important;
  }
  .colContainerTitleHorarios__title {
    font-size: 13px !important;
    text-align: center;
  }
  .fechaArea__p {
    padding-bottom: 20px;
    width: auto;
    max-width: 418px;
    text-align: center;
  }
  .colContainerButtons__regresar {
    font-size: 11px;
  }
  .colContainerButtons__continuar {
    padding-left: 15px !important;
    font-size: 13px;
    margin: 0;
  }
}
@media (max-width: 576px) {
  .containerFechaCita {
    width: 85%;
  }
  .rowContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .colContainerButtons__regresar {
    font-size: 11px;
  }
}
@media (min-width: 576px) and (max-width: 680px) {
  .rowNotAvailable {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .notAvailable {
    width: 100%;
    height: 100%;
    align-self: center;
    margin-left: 5%;
  }
}
@media (min-width: 681px) and (max-width: 767px) {
  .rowNotAvailable {
    align-items: center;
    justify-content: center;
    width: 107%;
    height: 100%;
  }
  .notAvailable {
    width: 90%;
    height: 90%;
    align-self: center;
  }
}
@media (min-width: 768px) {
  .notAvailable {
    width: 80%;
    height: 80%;
    align-self: center;
  }
  .fechaHeaderLeft {
    width: 100%;
  }
  .row_fecha {
    width: 100%;
  }
  .fechaArea {
    padding-top: 1.5%;
  }
}
@media (max-width: 768px) {
  .colContainerButtons__regresar {
    font-size: 11px;
  }
  .containerFechaCitaFluid {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .containerFechaCitaFluid {
    padding-top: 70px;
  }
  .alinearDerecha {
    justify-content: flex-end !important;
  }
  .alinearIzquierda {
    justify-content: flex-start !important;
  }
  .notAvailable {
    width: 75%;
    height: 75%;
    align-self: center;
  }
  .fechaHeaderLeft {
    width: 100%;
  }
  .fechaArea {
    padding-top: 1.5%;
  }
}
@media (min-width: 1200px) {
  .notAvailable {
    width: 80%;
    height: 80%;
    align-self: center;
  }
  .fechaHeaderLeft {
    width: 100%;
    padding-top: 1.5%;
    padding-bottom: 3%;
  }
}
@media (min-height: 1200px) {
  .containerFechaCitaFluid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    max-height: calc(100% - 275px);
  }
}
@media (min-width: 1500px) {
  .notAvailable {
    width: 70%;
    height: 70%;
    align-self: center;
  }
  .fechaHeaderLeft {
    width: 100%;
    padding-top: 1.5%;
    padding-bottom: 3%;
    padding-left: 0;
  }
}
/* //!Terminan estilos para Horarios */
