/* //! Aqui comienzan los nuevos estilos */

.containerCuestionario {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: rgba(102, 113, 128, 0.06);
  padding-top: 46px;
  padding-bottom: 50px;
}
.cuestionarioContainer {
  width: 100%;
  height: 100%;
  padding: 0;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rowContainer {
  width: 75%;
  height: 100%;
  margin: 0 auto;
}
.colContainer {
  height: 100%;
}
.rowContainerPadesimientos {
  height: 100%;
}
.colContainerPadesimientos {
  width: 75%;
  min-height: 300px;
  padding: 40px 0;
  border-radius: 20px;
  border: 4px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rowContainerTitle {
}
.colContainerTitle {
  padding-bottom: 30px;
}
.colContainerTitle__title {
  font-family: DINNextRoundedLTPro, sans-serif;
  font-size: 18px;
  color: #00606e;
  text-align: center;
}
.rowContainerAllPadesimientos {
  width: 100%;
  max-width: 655px;
  margin: 0 40px;
}
.colContainerOnePadesimiento {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 1px;
  max-width: 20%;
}
.formControlLabel {
  position: relative;
}
.formControlLabelDescription__p {
  margin: 0;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00748c;
  font-size: 14px;
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.formControlLabelDescription__lineaParrafo {
  margin: 0;
}
.rowContainerButtons {
  width: 30%;
  margin: 0;
}
.colContainerButtons {
  padding-top: 50px;
}
/* //! Aqui terminan los nuevos estilos */
@media (max-width: 400px) {
  .colContainerTitle__title {
    font-size: 15px;
  }
}

@media (max-width: 442px) {
  .cuestionarioContainer {
    /* padding-top: 0; */
  }
}
@media (max-width: 767px) {
  .rowContainerButtons {
    width: 70%;
  }
}
@media (max-width: 950px) {
  .formControlLabelDescription__p {
    font-size: 12px;
  }
}
@media (max-width: 991px) {
  .colContainerOnePadesimiento {
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .containerCuestionario{
    padding-top: 0;
  }
  .cuestionarioContainer {
    /* padding-top: 0; */
  }
}
@media (min-height: 1200px) {
  .containerCuestionario {
    padding-top: 50px;
    height: calc(100% - 206px);
  }
}
@media (min-width: 1500px) {
  .containerCuestionario {
    padding-top: 50px;
  }
}

.etiqueta {
  font-size: 20px;
  color: #00778b;
  font-family: DINNextRoundedLTPro, sans-serif;
}

.border {
  border: 1px solid !important;
  border-radius: 25px 25px 25px 25px !important;
  border-color: #00778b !important;
}

.checkbox {
  color: #10798b;
  font-family: DINNextRoundedLTPro, sans-serif;
}

.checkbox:hover {
  color: #ffcd00;
}
