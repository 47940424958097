.loginContainer {
  width: 100%;
  height: 100%;
  /* background-color: red; */
}

.rowPrincipalTitle {
  /* background-color: blue; */
  height: 15%;
  justify-content: center;
  align-items: center;
}

.rowBodyContainer {
  /* background-color: green; */
  height: 100%;
  justify-content: center;
  align-items: center;
}

.colBodyContainer_title {
  /* background-color: red; */
  height: 10%;
  padding: 0;
  margin: 0;
}

.rowTitle {
  padding: 0;
  margin: 0;
  margin-top: 3%;
  margin-bottom: 4%;
  justify-content: center;
  align-items: flex-end;
}

.colBodyContainer_form {
  /* background-color: orange; */
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.rowForm {
  height: 80%;
  width: 100%;
  padding: 0;
  margin: 0;
  justify-content: center;
}

.form {
  /* background-color: yellow; */
  width: 30%;
  height: 100%;
  border: 2px solid #ffd83d;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  -webkit-box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.39);
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.39);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error {
  margin-top: 10px;
  background-color: red;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 90%;
}

.formBody {
  height: 100%;
  /* background-color: red; */
  padding-top: 5%;
  /* align-items: center; */
}

.formRowItem {
  /* background-color: blue; */
  height: 20%;
  margin-top: 10%;
  margin-bottom: 10%;
}

.formColItem {
  /* background-color: green; */
  display: flex;
  align-items: flex-end;
  /* border: 1px solid black; */
  color: #00788a;
  font-weight: bold;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
}

.formColItem::after {
  content: " *";
  color: red;
  padding-bottom: 8px;
  margin-left: 2px;
}

.formColItemInput {
  /* background-color: green; */
  display: flex;
  justify-content: center;
  /* border: 1px solid black; */
}

.formColItemInput input {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: 16px;
}

.formColItemButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formColItemButton button {
  width: 40%;
  height: 35px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 50px;
  width: 100%;
  max-width: 244px;
  background-color: #ffd83d;
  color: #00788a;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  outline: none;
}

.formColItemButton button:hover {
  background-color: #00788a;
  color: #ffd83d;
}

.createAccountContainer {
}

.rowCreateAccount {
}

.colCreateAccount {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.createAccount {
  font-size: 14px;
  /* margin-top: 15px; */
  text-decoration: underline;
  color: #00788a;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.createAccount:hover {
  color: #ffd83d;
}
