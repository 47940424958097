.NoCitas {
  text-align: center;
}

.DropdownHorarios {
  border: 2px solid #00788a;
  border-radius: 12.5px;
  height: 48px;
  font-family: DINNextRoundedLTPro, sans-serif;
  font-size: 16px;
  color: #667180;
  width: 224px;
}

.DropdownHorarios:hover {
  color: #808080;
}

.ItemHorarios {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: DINNextRoundedLTPro, sans-serif;
  text-align: center;
  color: #808080;
}

.ItemHorarios:hover {
  background-color: #00748c;
  color: #fff;
}

.divMartin {
  display: flex;
  justify-content: center;
  align-items: center;
}
.divMartin__p{
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00606e;
  font-size: 18px;
}

.martinSinHorario {
  max-width: 200px;
}

.slick:before {
  color: #ffcc00 !important;
}

.eliminaContorno {
  outline: none !important;
}

/* Extra- Small devices verticales (landscape phones, 576px and up) */

@media (min-width: 300px) and (max-width: 400px) {
  .horaContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .horaContentPromo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .horarioContent {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .promoButtons {
    display: inline-flex;
    margin-bottom: 1.2rem;
    padding-left: 4%;
    padding-right: 4%;
  }
}

@media (min-width: 401px) and (max-width: 575px) {
  .horaContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .horaContentPromo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .horarioContent {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .promoButtons {
    display: inline-flex;
    margin-bottom: 1.2rem;
    padding-left: 4%;
    padding-right: 4%;
  }
}

/* Small devices  horizontales (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 680px) {
  .horaContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .horaContentPromo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .horarioContent {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .promoButtons {
    display: inline-flex;
    margin-bottom: 1.2rem;
    padding-left: 4%;
    padding-right: 4%;
  }
}

@media (min-width: 681px) and (max-width: 767px) {
  .horaContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .horaContentPromo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .horarioContent {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .promoButtons {
    display: inline-flex;
    margin-bottom: 1.2rem;
    padding-left: 4%;
    padding-right: 4%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .horarioContent {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .horaContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .horaContentPromo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .promoButtons {
    display: inline-flex;
    margin-bottom: 1.2rem;
    padding-left: 4%;
    padding-right: 4%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .horaContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .horarioContent {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .horaContentPromo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .promoButtons {
    display: inline-flex;
    margin-bottom: 1.2rem;
    padding-left: 4%;
    padding-right: 4%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1390px) {
  .horarioContent {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .horaContent {
    display: flex;
    justify-content: center;
  }

  .horaContentPromo {
    display: flex;
    justify-content: center;
  }

  .promoButtons {
    display: inline-flex;
    margin-bottom: 1.2rem;
  }

  .dateContent {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
}

/* Extra large devices (large desktops, 1390px and up) */

@media (min-width: 1391px) and (max-width: 1499px) {
  .horarioContent {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .horaContent {
    display: flex;
    justify-content: center;
  }

  .horaContentPromo {
    display: flex;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
  }

  .promoButtons {
    display: inline-flex;
  }

  .dateContent {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
}

/* Extra super large devices (large desktops, 1500px and up) */
@media (min-width: 1500px) {
  .horarioContent {
    display: flex;
    width: 100%;
  }

  .horaContent {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .horaContentPromo {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .promoButtons {
    display: inline-flex;
    margin-bottom: 1.2rem;
  }
}

.dateContent {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.rowContainer {
  /* width: 100%;
  margin-left: auto;
  margin-right: auto; */
  margin: 0 auto;
  width: 80%;
}

@media (max-width: 576px) {
  .rowContainer {
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
  }
}
@media(max-width:443px){
  .rowContainer {
    width: 100%;
  }
}