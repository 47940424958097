.container {
  min-height: 305px;
  height: 100%;
  width: 100%;
  border: 4px solid #fff;
  border-radius: 20px;
}
.rowContainer {
  height: 100%;
  width: 100%;
}
.colContainerForm {
  height: 100%;
  padding: 0;
}
.form {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.formContainer {
  height: 100%;
}
.rowTitle {
  /* margin-bottom: -20px; */
}
.colTitle {
}
.colTitle__title {
  color: #10798b;
  font-family: DINNextRoundedLTPro, sans-serif;
  text-decoration: underline;
  font-size: 22px;
}
.rowData {
}
.colNameContainer {
}
.rowNameContaine {
}
.colTitleName {
}
.colTitleName__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #10798b;
  font-size: 16px;
}
.colName {
}
.colName__input,
.colName__input:focus {
  width: 100%;
  max-width: 300px;
  height: 50px;
  border: 2px solid #10798b;
  border-radius: 12.5px;
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  background-color: transparent;
  font-size: 18px;
  padding-left: 10px;
  outline: none;
}
.colName__input::placeholder {
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
}
.colEmailContainer {
}
.rowEmailContainer {
}
.colTitleEmail {
}
.colTitleEmail__title {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #10798b;
  font-size: 16px;
}
.colEmail {
}
.colEmail__input,
.colEmail__input:focus {
  width: 100%;
  max-width: 300px;
  height: 50px;
  border: 2px solid #10798b;
  border-radius: 12.5px;
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  background-color: transparent;
  font-size: 18px;
  padding-left: 10px;
  outline: none;
}
.colEmail__input::placeholder {
  color: rgb(146, 141, 141);
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  font-size: 18px;
}

.colMensajeError {
  display: flex;
  justify-content: center;
  align-items: center;
}
.colMensajeError__p {
  font-family: DINNextRoundedLTPro-Regular, sans-serif;
  color: #10798b;
  font-size: 16px;
}

@media (max-width: 767px) {
  .rowEmailContainer {
    padding-top: 15px;
  }
}

@media (max-width: 768px) {
  .container {
    padding-bottom: 20px;
  }
}
@media (max-width: 443px) {
  .colName__input,
  .colName__input:focus {
    min-width: 230px;
  }
  .colEmail__input,
  .colEmail__input:focus {
    min-width: 230px;
  }
}
