body {
  background: #FFF;
  /*margin-top: 25px;*/
  /*font-weight: bold;*/
  /* padding: 40px;  */
  /* font-size: 15px; */
  font-family: sans-serif,'Arial Unicode MS';
  color: #428194;


      } 


      .grafica{

        background-color: rgb(216, 36, 105);
      }

  .headerPrint{
    position: fixed;
    top: 0;
    right: 0;
    padding: 30px; 

  }

  .saltoDePagina{
      PAGE-BREAK-AFTER: always;
  }


  .fS30{
    font-size: 30;
  }

  .fS18{
    font-size: 18px;
  }
  .titulo{
      margin-top: 50px;
      width: 100%;
      height: auto;
      bottom: 0;
      text-align: center;
      font-size:  25px;
  }

  .firma{
    margin-top: 50px;
    width: 100%;
    height: auto;
    bottom: 0;
    text-align: center;
    font-size:  18px;
}

  .pt50{
    padding-top: 50px;

  }
  .pt20{
    padding-top: 10px;

  }
  .pt30{
    padding-top: 30px;

  }

  .pt90{
    padding-top: 90px;

  }

  .pt95{
    padding-top: 95px;

  }

  .pt100{
    padding-top: 180px;

  }
  .cabecera{
      width: 100%;
      height: auto;
      bottom: 0;
      color: #428194;
      font-size:  15px;
      text-align: right;
      vertical-align: middle; 
      display: inline;
      float:right;

  }


  .mainContainer{
      width: 100%;
      position: relative;
  }
  .containerSignosVitales {
    width: 95%;
    height: 100%;
    /* background-color: red; */
  }
  .fontSizeBody{
    font-size:  20px;

  }
  .rowContainerGraph {
    padding: 0;
    margin: 0;
    /* background-color: blue; */
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoSu{
      width: 50%;
      position: relative;
  }

  .mbt{
    margin-top: 5px;
    margin-bottom: 5px;

  }


  .tabla td, th {
      border: 1px solid #dddddd;
      padding: 3px 14px ;
  }
  .tabla {
      border-collapse: collapse;
      width: 100%;
  }

  .bordesVerdes{
      border-color: #00758D;
  }
  .bordesVerdes td, th{
      border: 0.5px solid #00758D;
  }
  .fuenteVerde{
      color: #00758D;
  }
  .info{
      font-weight: normal;
      text-align: center;
  }
  .piedepagina{
    width: 100%;
    height: auto;
    bottom: 0;
    text-align: center;
    
    position: fixed;
  }
  .valDato{
    color: black;
    border-bottom: 0.5px solid #00758D;
    max-width: 200px;
    

}

.margenDerecho{
  float: right; 
  display:inline;
  padding-right: 60px;
}

.margenIzquierdo{
  float: left; 
  display:inline;
  padding-right: 60px;
}




/* .esquinaDerecha{
  float: right; 
  display:inline;
  position: absolute;
  top: 1420px;

  right: 0;
  
}

.esquinaDerecha2{
  float: right; 
  display:inline;
  position: absolute;
  top: 2930px;

  right: 0;
  
} */


.linea-limpia-cred{
  border-top: 1px solid #00758D;
  height: 10px;
  max-width: 200px;
  padding: 0;
  margin: -3px 0 0 130px;

}
.linea-limpia-fini{
  border-top: 1px solid #00758D;
  height: 10px;
  max-width: 200px;
  padding: 0;
  margin: -3px 0 0 130px;

}

.linea {
  border-top: 1px solid black;
  height: 2px;
  max-width: 200px;
  padding: 0;
  margin: 50px auto 0 auto;
}

.centrado{
  width: 100%;
  height: auto;
  text-align: center;    
  }
  .saltoDePagina{
    PAGE-BREAK-AFTER: always;
}
.contenido{
  margin-top:10px;
  font-size: 18px !important;
}



.tab-1{
  padding: 0 0 0 38px;
}
.tab-2{
  padding-left: 55px;
}
.fondoVerdeObscuro{
  background: #00758D;
  color: #FFFFFF;
  text-align: center;
}




@media print {

@media  (max-height: 2000px) {
  .esquinaDerecha{
    float: right; 
    display:inline;
    position: absolute;
    top: 1420px;
    background-color: yellow;

    right: 0;
    
  }

  .esquinaDerecha2{
    float: right; 
    display:inline;
    position: absolute;
    top: 2930px;
    background-color: green;

    right: 0;
    
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media  (max-height: 1070px) {
.esquinaDerecha{
  float: right; 
  display:inline;
  position: absolute;
  top: 1420px;
  background-color: red;
  right: 0;
  
}

.esquinaDerecha2{
  float: right; 
  display:inline;
  position: absolute;
  top: 2930px;
background-color: blue;
  right: 0;
  
}
}

.pagebreak {
    break-after: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
}

.fS18{
  font-size:18px;
}



@page :first {

}


}