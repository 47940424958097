.btn {
  transition: none;
}

.contenedor {
  width: 100%;
  height: 100%;
}

.underlineThrough {
  text-decoration: line-through;
}

.serviceBtn {
  display: flex;
  justify-content: center;

  /* padding-left: 15px;
    padding-right: 15px; */
}

/*
.serviceBtnElement{
    width: 100%;
    height: 275px;
    font-size: 25px;
    color: #00788b;
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #00788b;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoBold, sans-serif;
} */

.fecha-btn {
  /* padding-top: 30px; */

  /* padding-left: 15px;
    padding-right: 15px; */
  padding-left: 5%;
  width: 100%;
}

.fechaBtnElement,.fechaBtnElement:focus {
  /* height: 13.4vh; */
  width: 110px;
  height: 110px;
  font-size: 16px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  white-space: normal;
  font-weight: normal;
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00606e;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 50%;
  outline: none;
}

.fechaBtnElementActivo,
.fechaBtnElementActivo:focus {
  /* height: 13.4vh; */
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
  color: #00606e;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #ffd83d;
  box-shadow: none;
  width: 110px;
  height: 110px;
  font-size: 16px;
  white-space: normal;
  font-weight: normal;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 50%;
  position: relative;
  outline: none;
}
.fechaBtnElementActivo::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  content: url("../../images/svg/general__accept-path.svg");
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ffcd00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerTextBotonFecha {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.semanaDia {
  margin-bottom: -0.3rem;
}

.numeroDia {
  font-size: 16px;
  margin-bottom: -0.3rem;
}

.mesDia {
  margin-bottom: 0;
}

.transparente {
  color: transparent;
}

.horarioBtn {
  display: flex;
  justify-content: center;
  width: 100%;
}
.horarioBtnIzq {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 20px;
}
.horarioBtnDer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 20px;
}
.hora-row {
  display: flex;
  width: 90%;
  padding-top: 1.3%;
}

.active {
  color: #fff;
  background-color: #00788b;
  border-radius: 10px;
  border: 2px solid #00788b;
  box-shadow: none;
  outline: none;
}

.p_hora {
  position: relative;
  padding-top: 20%;
}

.dinFont {
  font-family: DINNextRoundedLTPro-Medium, sans-serif;
}

.secondaryBlue {
  color: #00606e;
}

.newPrice {
  color: #fdcd2f;
  opacity: 0;
  -webkit-animation: price-animation 0s;
  -webkit-animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
  animation: price-animation 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.horarioBtnElementActivo {
  width: 110px;
  height: 110px;
  font-size: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ffd83d;
  font-family: DINNextRoundedLTPro, sans-serif;
  position: relative;
}

.horarioBtnElementActivo::after {
  position: absolute;
  top: 0;
  right: 15px;
  width: 20px;
  height: 20px;
  content: url("../../images/svg/general__accept-path.svg");
  border-radius: 50%;
  background-color: #ffcd00;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.horarioBtnElementInactivo {
  width: 110px;
  height: 110px;
  font-size: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  font-family: DINNextRoundedLTPro, sans-serif;
}

/* Code for old Chrome, Safari and Opera */
@-webkit-keyframes price-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Standard syntax */
@keyframes price-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Extra- Small devices verticales (landscape phones, 576px and up) */
@media (max-width: 443px) {
  .fechaBtnElementActivo::after {
    width: 20px;
    height: 20px;
    right: 5px;
  }
  .semanaDia {
    font-size: 12px;
  }
  .dinFont {
    font-size: 12px;
  }
  .mesDia {
    font-size: 12px;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .horaBtnElement {
    /* height: 13.4vh; */
    width: 88px;
    font-size: 1.1rem;
    color: #00788b;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .horaBtnElementActivo {
    width: 88px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #00788b;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .serviceBtnElement {
    width: 286px;
    height: 200px;
    font-size: 15px;
    color: #00788b;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #bababa;
    font-family: ChivoRegular, sans-serif;
    padding-left: 0;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
  }

  .div_imgServicio {
    width: 30%;
    height: 100%;
    float: left;
    z-index: 1;
    background-color: #ededed;
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px;
  }

  .div_textoServicio {
    width: 100%;
    padding-left: 33.5%;
    padding-top: 4.8%;
    text-align: left;
    padding-right: 1%;
  }
  .contenedorDescripcionMovil {
    color: #5c5c5c;
    font-size: 0.8rem;
    margin: 0;
    font-family: ChivoRegular, sans-serif;
    margin-bottom: 10px;
  }

  .iconoServicio {
    width: 80%;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 401px) and (max-width: 575px) {
  .horaBtnElement {
    /* height: 13.4vh; */
    width: 88px;
    font-size: 1.1rem;
    color: #00788b;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .horaBtnElementActivo {
    width: 88px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #00788b;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .serviceBtnElement {
    width: 286px;
    height: 200px;
    font-size: 15px;
    color: #00788b;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #bababa;
    font-family: ChivoRegular, sans-serif;
    padding-left: 0;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
  }

  .div_imgServicio {
    width: 30%;
    height: 100%;
    float: left;
    z-index: 1;
    background-color: #ededed;
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px;
  }

  .div_textoServicio {
    width: 100%;
    padding-left: 33.5%;
    padding-top: 4.8%;
    text-align: left;
    padding-right: 1%;
  }

  .contenedorDescripcionMovil {
    color: #5c5c5c;
    font-size: 0.8rem;
    margin: 0;
    font-family: ChivoRegular, sans-serif;
    margin-bottom: 10px;
  }

  .iconoServicio {
    width: 80%;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  .img_boton_horario {
    width: 100%;
  }
}

/* Small devices  horizontales (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 680px) {
  .horaBtnElement {
    /* height: 13.4vh; */
    width: 88px;
    font-size: 1.1rem;
    color: #00788b;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .horaBtnElementActivo {
    width: 88px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #00788b;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .serviceBtnElement {
    width: 286px;
    height: 200px;
    font-size: 15px;
    color: #00788b;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #bababa;
    font-family: ChivoRegular, sans-serif;
    padding-left: 0;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
  }

  .div_imgServicio {
    width: 30%;
    height: 100%;
    float: left;
    z-index: 1;
    background-color: #ededed;
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px;
  }

  .div_textoServicio {
    width: 100%;
    padding-left: 33.5%;
    padding-top: 4.8%;
    text-align: left;
    padding-right: 1%;
  }

  .contenedorDescripcionMovil {
    color: #5c5c5c;
    font-size: 0.8rem;
    margin: 0;
    font-family: ChivoRegular, sans-serif;
    margin-bottom: 10px;
  }

  .iconoServicio {
    width: 80%;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 681px) and (max-width: 767px) {
  .horaBtnElement {
    /* height: 13.4vh; */
    width: 88px;
    font-size: 1.1rem;
    color: #00788b;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .horaBtnElementActivo {
    width: 88px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #00788b;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .serviceBtnElement {
    width: 286px;
    height: 200px;
    font-size: 15px;
    color: #00788b;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #bababa;
    font-family: ChivoRegular, sans-serif;
    padding-left: 0;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
  }

  .div_imgServicio {
    width: 30%;
    height: 100%;
    float: left;
    z-index: 1;
    background-color: #ededed;
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px;
  }

  .div_textoServicio {
    width: 100%;
    padding-left: 33.5%;
    padding-top: 4.8%;
    text-align: left;
    padding-right: 1%;
  }
  .contenedorDescripcionMovil {
    color: #5c5c5c;
    font-size: 0.8rem;
    margin: 0;
    font-family: ChivoRegular, sans-serif;
    margin-bottom: 10px;
  }

  .iconoServicio {
    width: 80%;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 750px) {
  .horarioBtnElementActivo {
    width: 100px;
    height: 100px;
  }
  .horarioBtnElementInactivo {
    width: 100px;
    height: 100px;
  }
  .horarioBtnElementActivo::after {
    right: 10px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .horaBtnElement {
    /* height: 13.4vh; */
    width: 88px;
    font-size: 1.1rem;
    color: #00788b;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .horaBtnElementActivo {
    width: 88px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #00788b;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .serviceBtnElement {
    width: 286px;
    height: 200px;
    font-size: 15px;
    color: #00788b;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #bababa;
    font-family: ChivoRegular, sans-serif;
    padding-left: 0;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
  }

  .div_imgServicio {
    width: 30%;
    height: 100%;
    float: left;
    z-index: 1;
    background-color: #ededed;
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px;
  }

  .div_textoServicio {
    width: 100%;
    padding-left: 33.5%;
    padding-top: 4.8%;
    text-align: left;
    padding-right: 1%;
  }

  .contenedorDescripcionMovil {
    color: #5c5c5c;
    font-size: 0.8rem;
    margin: 0;
    font-family: ChivoRegular, sans-serif;
    margin-bottom: 10px;
  }

  .iconoServicio {
    width: 80%;
    height: 100%;
    padding-top: 95%;
    padding-bottom: 97%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
  .fechaBtnElementActivo {
    width: 90px;
    height: 90px;
  }
  .fechaBtnElement {
    width: 90px;
    height: 90px;
  }
}
@media (min-width: 992px) {
  .horaBtnElement {
    /* height: 13.4vh; */
    width: 88px;
    font-size: 1.1rem;
    color: #00788b;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .horaBtnElementActivo {
    width: 88px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #00788b;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .serviceBtnElement {
    width: 286px;
    font-size: 15px;
    color: #00788b;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #bababa;
    font-family: ChivoRegular, sans-serif;
    padding-left: 0;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
  }

  .div_imgServicio {
    width: 30%;
    height: 100%;
    float: left;
    z-index: 1;
    background-color: #ededed;
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px;
  }

  .div_textoServicio {
    width: 100%;
    padding-left: 33.5%;
    padding-top: 4.8%;
    text-align: left;
    padding-right: 1%;
  }

  .contenedorDescripcionMovil {
    color: #5c5c5c;
    font-size: 0.8rem;
    margin: 0;
    font-family: ChivoRegular, sans-serif;
    margin-bottom: 10px;
  }

  .iconoServicio {
    width: 80%;
    height: 100%;
    padding-top: 95%;
    padding-bottom: 95%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .horaBtnElement {
    /* height: 13.4vh; */
    width: 88px;
    font-size: 1.1rem;
    color: #00788b;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .horaBtnElementActivo {
    width: 88px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #00788b;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .serviceBtn {
    padding-top: 1%;
    height: 100%;
  }

  .serviceBtnElement {
    width: 100%;
    height: 120px;
    font-size: 26px;
    color: #00788b;
    background-color: #fff;
    border-radius: 19px;
    border: 1px solid #bababa;
    line-height: 28px;
    font-family: ChivoRegular, sans-serif;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .div_imgServicio {
    /* height: 100%; */
    width: 30%;
    height: 117px;
    float: left;
    z-index: 1;
    background-color: #ededed;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 17px;
    margin-left: 0;
    padding-top: 0%;
  }

  .div_textoServicio {
    width: 100%;
    padding-left: 33.5%;
    padding-top: 4.8%;
    text-align: left;
    padding-right: 1%;
  }

  .p_Servicio {
    margin-bottom: 4%;
  }

  .contenedorDescripcionMovil {
    display: none;
  }

  .contenedorDescripcion {
    width: 100%;
    height: 100%;
    padding-top: 1.5%;
    color: #5c5c5c;
    font-size: 1.2rem;
    padding-left: 15px;
    padding-right: 15px;
  }

  .iconoServicio {
    width: 53%;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* Extra super large devices (large desktops, 1500px and up) */
@media (min-width: 1500px) {
  .horarioBtn {
    position: relative;
  }
  .horarioBtnIzq {
    position: relative;
  }
  .horarioBtnDer {
    position: relative;
  }

  .horaBtnElement {
    /* height: 13.4vh; */
    width: 88px;
    font-size: 1.1rem;
    color: #00788b;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .horaBtnElementActivo {
    width: 88px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #00788b;
    border-radius: 10px;
    border: 1px solid #bababa;
    white-space: normal;
    font-weight: bold;
    font-family: ChivoRegular, sans-serif;
    margin: 6px 6px;
    padding-top: 1rem;
  }

  .serviceBtnElement {
    width: 500px;
    height: 120px;
    font-size: 21px;
    color: #00788b;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #bababa;
    line-height: 28px;
    font-family: ChivoRegular, sans-serif;
    padding-left: 0;
    display: flex;
    justify-content: center;
  }

  .div_imgServicio {
    width: 30%;
    height: 100%;
    float: left;
    z-index: 1;
    background-color: #ededed;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-top: 7%;
  }

  .div_textoServicio {
    width: 100%;
  }

  .iconoServicio {
    width: 57%;
    height: 50%;
    padding-top: 0;
    padding-bottom: 0;
    padding-bottom: 35px;
  }
}
