.containerSalauno{
  width: 100%;
  height: calc(100% - 206px);
  padding: 0;
  margin: 0;
}
@media(max-width:443px){
  .containerSalauno{
    height: calc(100% - 140px);
  }
}